import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule ,PLATFORM_ID} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthModule } from '../app/views/auth/auth.module';
import { AppState } from './core/_store/app.state';
import { InterceptService } from './core/_auth/services/intercept.service';
import { DataService } from './core/_services/data.service';
import { ApiConstants } from './core/_utils/apiconstants';
import { ThemeModule } from './views/theme/theme.module';
import { AuthService } from './core/_auth/services/auth.service';
import { DealService } from './core/_services/partner.service';
import { DealsService } from './core/_services/deals.service';
import { CartService } from './core/_services/cart.service';
import { ChatMessageService } from './core/_services/chat.message.service';
import { RegistrationService } from './core/_auth/services/registration.service';
import { AuthGuard } from './core/_auth';
import { AuthAccountService } from './core/_services/auth.account.service';
import { OrderService } from './core/_services/order.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { LoaderService } from './services/loader.service';
import { LocalStorageService } from './core/_services/local-storage.service';
import { RatingService } from './core/_services/rating.service';
import { CouponService } from './core/_services/coupon.service';
import { UserQueryService } from './core/_services/user.query.service';
import { BadWordsService } from './core/_services/badwords.service';
import { AuthStateGuard } from './core/_auth/_guard/authState.guard';
import { SocketService } from './services/socket.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { DropzoneDirective } from './dropzone.directive';
// import { NgSelectModule } from '@ng-select/ng-select';


declare var FB: any;

export function initializeConfig(
  dataService: DataService,
  appState: AppState,
  cookieService: CookieService,
  platformId: Object
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      dataService
        .get('assets/config/application-config.json')
        .subscribe((resp) => {
          let res: any = resp;
          appState.set(ApiConstants.REST_URL, res.rest_url);
          appState.set(ApiConstants.GOOGLE_API_KEY, res.google_api_key);
          appState.set(ApiConstants.GOOGLE_CLIENT_ID, res.google_client_id);
          appState.set(ApiConstants.PAYMENT_WEBURL, res.payment_weburl);
          appState.set(ApiConstants.FACEBOOK_APP_ID, res.facebook_app_id);
          // console.log('API...', res.rest_url);

          let fbAppId = appState.get(ApiConstants.FACEBOOK_APP_ID);

          console.log('adding fbAsyncInit in app module');
          window['fbAsyncInit'] = function () {
            // initialize FB instance
            FB.init({
              appId: fbAppId,
              cookie: true, // Enable cookies to allow the server to access the session.
              xfbml: true, // Parse social plugins on this webpage.
              version: 'v18.0', // Use this Graph API version for this call.
            });
          };

          // load facebook sdk script
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'facebook-jssdk');
          resolve();
        });
    });
  
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // DropzoneDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    ThemeModule,
    NgxSkeletonLoaderModule.forRoot()
    // NgSelectModule,
  ],
  providers: [
    AuthStateGuard,
    AuthGuard,
    AppState,
    AuthAccountService,
    DataService,
    AuthService,
    RegistrationService,
    DealService,
    DealsService,
    CartService,
    OrderService,
    RatingService,
    CouponService,
    ChatMessageService,
    LocalStorageService,
    UserQueryService, 
    BadWordsService,
    SocketService,
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeConfig, 
      deps: [DataService, AppState, CookieService,PLATFORM_ID],
      multi: true,
    },
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
