<div class="newsletter-wrapper">
  <div class="newsletter-inner">
    <div class="container">
      <div class="newsletter-contentWrap">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="newsletter-textBox">
              <h2 class="m-0">Never Miss Out on Savings! Subscribe by <br>
                Email to <span class="text-orange">LocolDeal</span> for Exclusive Coupons and Deals</h2>

              <h2 class="mt-4" *ngIf="IsDeal()"> If you are looking for Coupons,  
                  <u class="text-orange" [routerLink]="'/customer/coupons-browse'" style="cursor: pointer;" >Click Here</u> </h2>
              <p class="mt-4" *ngIf="!IsDeal()">Immerse yourself in a world where consumers always discover unparalleled <br> local deals,
                discounts, and
                exclusive offers tailored just for you <br> your journey to endless savings begins here.</p>
              <form class="mt-5" *ngIf="!IsDeal()">
                <div class="form-group">
                  <input type="email" class="form-control" id="newsletterEmail" placeholder="Enter Your Email Address">
                  <button type="submit" class="btn btn-primary">➜</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-3">
            <div class="newsletter-imgBox">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>