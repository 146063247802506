
export class Product {
    dealId: number;
    productId: number;
    productName: string;
    productType: string;
    productDescription: string;
    normalPrice: number;
    dealPrice: number;
    unit: number;
}