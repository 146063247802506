export class AppConstants {

    public static readonly TOKEN_VALIDITY = 5 * 60 * 60 * 1000;
    public static readonly COOKIE_TOKEN = "_token_";
    public static readonly VERIFY_EMAIL = 'verify_email';
    public static readonly VERIFY_USERNAME = 'verify_username';
    public static readonly USERNAME = "username";
    public static readonly USER_ID = "userid";
    public static readonly USER_FULLNAME = "user_fullname";
    public static readonly USER_DETAILS = "user_details";
    public static readonly USER_ROLE = "user_role";

    public static readonly DEAL_EDIT = 'deal_edit';
    public static readonly SELLER_ACC_VERIFIED = "seller_account_verified";

    public static readonly WEB_SOCKET_URL = 'https://chat-ws.locoldeal.com/';
}