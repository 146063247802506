<section class="home_banner-wrapper" #dealsOfTheDay>
    <div class="home_banner-inner">
        <div class="container custom-container">
            <div class="home_banner-contentwrap">
                <div class="home_banner-contentwrap-inner">
                    <h1>
                        Promote Your Business With
                        <span style="color: #ee410d">LocolDeal</span>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="form-inner">
        <h2 class="form-title">register Your Business with <span style="color: #ee410d">LocolDeal</span> Business</h2>
        
        <div class="form-body">
            <form [formGroup]="registrationForm" autocomplete="off" [hidden]="showVerifyEmail">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="businessname"
                    value=""
                    placeholder="Business Name"
                  />
                  <mat-error *ngIf="hasError('businessname', 'required')">
                    First Name is required
                  </mat-error>
                  <mat-error *ngIf="hasError('businessname', 'minlength')">
                    Min 3 characters required
                  </mat-error>
                  <mat-error *ngIf="hasError('businessname', 'maxlength')">
                    Max 100 characters are allowed
                  </mat-error>
                </div>

                <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="businessAddress"
                      value=""
                      placeholder="Business Address"
                    />
                </div>

                <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="firstname"
                      value=""
                      placeholder="First Name"
                    />
                    <mat-error *ngIf="hasError('firstname', 'required')">
                      First Name is required
                    </mat-error>
                    <mat-error *ngIf="hasError('firstname', 'minlength')">
                      Min 3 characters required
                    </mat-error>
                    <mat-error *ngIf="hasError('firstname', 'maxlength')">
                      Max 100 characters are allowed
                    </mat-error>
                  </div>
              
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lastname"
                    placeholder="Last Name"
                  />
                  <mat-error *ngIf="hasError('lastname', 'required')">
                    Last Name is required
                  </mat-error>
                  <mat-error *ngIf="hasError('lastname', 'maxlength')">
                    Max 100 characters are allowed
                  </mat-error>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    placeholder="Email Address"
                  />
                  <mat-error *ngIf="hasError('email', 'required')">
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="hasError('email', 'maxlength')">
                    Max 100 characters are allowed
                  </mat-error>
                  <mat-error *ngIf="hasError('email', 'email')">
                    Invalid Email address
                  </mat-error>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phone"
                    value=""
                    placeholder="Phone Number"
                  />
                  <mat-error *ngIf="hasError('phone', 'required')">
                    Phone Number is required
                  </mat-error>
                  <mat-error *ngIf="hasError('phone', 'minlength')">
                    Number should be minimum 5 digits
                  </mat-error>
                  <mat-error *ngIf="hasError('phone', 'maxlength')">
                    Number can be maximum 20 digits
                  </mat-error>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    value=""
                    placeholder="User Name"
                  />
                  <mat-error *ngIf="hasError('username', 'required')">
                    User Name is required
                  </mat-error>
                  <mat-error *ngIf="hasError('username', 'minlength')">
                    Min 3 characters required
                  </mat-error>
                  <mat-error *ngIf="hasError('username', 'maxlength')">
                    Max 100 characters are allowed
                  </mat-error>
                </div>
                <div class="form-group">
                  <span class="text">
                    <input
                      class="form-control"
                      formControlName="password"
                      type="password"
                      value=""
                      placeholder="Password"
                    />
                  </span>
                  <mat-error *ngIf="hasError('password', 'required')">
                    Password is required
                  </mat-error>
                  <mat-error *ngIf="hasError('password', 'minlength')">
                    Password should be minimum 6 character (alpha-numeric)
                  </mat-error>
                  <mat-error *ngIf="hasError('password', 'maxlength')">
                    Password can be maximum 100 character (alpha-numeric)
                  </mat-error>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    formControlName="confirmPassword"
                    type="password"
                    value=""
                    placeholder="Confirm Password"
                  />
                  <mat-error *ngIf="hasError('confirmPassword', 'required')">
                    Confirm Password is required
                  </mat-error>
                  <mat-error *ngIf="hasError('confirmPassword', 'minlength')">
                    Confirm Password should be minimum 6 character (alpha-numeric)
                  </mat-error>
                  <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">
                    Confirm Password can be maximum 100 character (alpha-numeric)
                  </mat-error>
                  <mat-error *ngIf="hasError('confirmPassword', 'ConfirmPassword')">
                    Password does not match
                  </mat-error>
                </div>

                <div class="form-group">
                    <label>Type Of Business</label>
                    <select class="form-control selectDropdown" formControlName="businessCategory" data-tags="true" data-allow-clear="true" name="category" id="businessType" required data-placeholder="Select Business Category">
                        <option value="-1">Choose a category</option>
                        <!-- <option>category 1</option>
                        <option>category 2</option>
                        <option>category 3</option> -->
                        <option *ngFor="let e of dealCategories" [value]="e.categoryName">
                          {{ e.categoryName }}
                        </option>
                        <mat-error *ngIf="hasError2('category', 'required')">
                          <i class="fa fa-info-circle me-1" aria-hidden="true"></i>Category is
                          required
                        </mat-error>
                    </select>
                  
                </div>
              
                <div class="form-group">
                  <span class="Checkbox">
                    <label>
                      <input
                        id="toc"
                        type="checkbox"
                        class="mr-2"
                        name=""
                        (change)="agreeTermsAndCondition($event)"
                        value="" />
                        By clicking, I agree to the <span class="cursor-pointer" routerLink="/customer/terms-conditions" style="color: #ee410d">Terms of use </span>and that i have read the <span style="color: #ee410d">privacy
statement</span>
                      <a
                        routerLink="/customer/terms-conditions"
                        class="ml-2"
                        target="_blank"
                        title="View Terms & Conditions"
                        data-dismiss="modal"
                      >
                        <i class="fa fa-external-link" aria-hidden="true"></i></a
                    ></label>
                  </span>
                </div>
              
                <div class="btnWrap">
                  <button
                    type="submit"
                    [disabled]="isButtonDisabled"
                    id="submitBtn"
                    (click)="submit()"
                    class="create-account-disabled submit-btn"
                  >
                    Create Account
                  </button>
                </div>
            </form>


            <app-verify-email *ngIf="showVerifyEmail"
                  (onVerifySuccess)="callbackOnVerified()"
                  (onVerifyCancelled)="callbackOnVerifyCancel()"
            ></app-verify-email>
        </div>
    </div>
</div>

<br><br>