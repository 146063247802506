import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/core/_services/coupon.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit {
  searchText: string;
  searchLocation: string;

  // showSearchProgress: boolean;

  constructor(private router: Router, private couponService: CouponService) {
    this.searchText = '';
    this.searchLocation = '';
  }

  ngOnInit(): void {}
  doSearch() {
    console.log(this.searchText, this.searchLocation);
    /*
    if (this.searchText == 'pizzahut') {
      this.router.navigate(['/customer/seller-home', { s: this.searchText }]);
      return;  
    } */

    // this.showSearchProgress = true;

    this.couponService.brandSearch(this.searchText).subscribe(
      (resp) => {
        if (this.searchText && resp && resp.length > 0) {
          this.router.navigate([
            '/customer/seller-home',
            { s: this.searchText },
          ]);
        } else {
          console.log('no brands found with search');
          this.router.navigate([
            '/customer/product-search',
            { q: this.searchText, location: this.searchLocation },
          ]);
        }

        // this.showSearchProgress = false;
      },
      (err) => {
        console.log('some error with searching for brand');
        // this.showSearchProgress = false;
      }
    );
  }
}
