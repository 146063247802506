<div class="header-loginbtnsWrap">
  <ul class="navbar-nav">
    <li class="nav-item chatToggle">
      <a
        class="nav-link"
        role="button"
        (click)="toggleChatWindow()"
        title="Chat Messages"
      >
        <i class="fa fa-comments-o" aria-hidden="true"></i>
        <span class="notification-dot" *ngIf="notificationCount">{{notificationCount}}</span>
      </a>
    </li>
    <!-- <li class="nav-item">
      <a
        class="nav-link"
        role="button"
        title="Likes"
      >
        <i class="fa fa-heart-o" aria-hidden="true"></i>
      </a>
    </li> -->
    <li class="nav-item">
      <a
        class="nav-link"
        role="button"
        (click)="toggleNotifWindow()"
        title="Notifications"
      >
        <i class="fa fa-bell-o" aria-hidden="true"></i>
      </a>
    </li>
    <li class="nav-item">
      <a routerLink="/customer/cart" class="nav-link" title="Cart">
        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
      </a>
    </li>
    <li class="nav-item dropdown profile-dropdown">
      <a
        class="nav-link"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Profile"
      >
        <!-- <i class="fa fa-user" aria-hidden="true"></i> -->
        <img
          src="assets/images/avatar-user.svg"
          class="w-100 avatarImg"    
          alt="QR code"
        />
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <ul class="profile-dropdown-list">
          <li class="profile-dropdown-item">
            <a
              class="profile-dropdown-link"
              routerLink="/customer/cuser-profile"
              title="My Profile"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
              My Profile
            </a>
          </li>
          <li class="profile-dropdown-item">
            <a
              class="profile-dropdown-link"
              routerLink="/customer/order-list"
              title="My Orders"
            >
              <i class="fa fa-shopping-basket" aria-hidden="true"></i>
              My Orders
            </a>
          </li>
          <li class="profile-dropdown-item">
            <a
              class="profile-dropdown-link"
              routerLink="/customer/csaved-coupons"
              title="Coupons"
            >
              <i class="fa fa-money" aria-hidden="true"></i>
              My Saved Coupons
            </a>
          </li>
          <li class="profile-dropdown-item">
            <a
              class="profile-dropdown-link"
              routerLink="/customer/csaved-deals"
              title="My Saved deals"
            >
              <i class="fa fa-heart" aria-hidden="true"></i>
              My Saved deals
            </a>
          </li>
          <li class="profile-dropdown-item">
            <a
              class="profile-dropdown-link"
              routerLink="/customer/dashboard"
              title="Recently Viewed"
            >
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Recently Viewed
            </a>
          </li>
          <li class="profile-dropdown-item d-none">
            <a class="profile-dropdown-link" routerLink="#" title="Settings">
              <i class="fa fa-cog" aria-hidden="true"></i>
              Settings
            </a>
          </li>
          <li class="profile-dropdown-item">
            <a class="profile-dropdown-link" (click)="logout()" title="Logout">
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              Logout
            </a>
          </li>
          <li class="profile-dropdown-item">
            <ul class="profile-dropdown-bottom-list">
              <li class="profile-dropdown-bottom-item">
                <a href="/customer/faq-consumer" target="_blank" class="profile-dropdown-bottom-link" title="FAQ"
                  >FAQ</a
                >
              </li>
              <li class="profile-dropdown-bottom-item">
                <a class="profile-dropdown-bottom-link" routerLink="/customer/user-query" title="Customer Support"
                  >Customer Support</a
                >
              </li> 
            </ul>
          </li>
        </ul>
        <div *ngIf="false" class="float-right">
          <span class="header-user-greeting" [hidden]="!login_state">
            Hi {{ userFullName }}
          </span>
        </div>
      </div>
    </li>
  </ul>
</div>
<!-- Chat Window -->
<ng-container *ngIf="chatToggle">
  <app-customer-chat (close)="onChatClose($event)"></app-customer-chat>
</ng-container>

<ng-container *ngIf="notifWindowToggle">
  <app-common-notifications></app-common-notifications>
</ng-container>
