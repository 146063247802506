import { Injectable } from '@angular/core';

export type InternalState = {
    [key: string]: any;
}

@Injectable()
export class AppState {

    _state: InternalState = {};

    constructor() { }

    get state() {
        return this._state = this._clone(this._state);
    }


    get(prop?: any) {
        const state = this._state;
        return state.hasOwnProperty(prop) ? state[prop] : null;
    }

    set(prop: string, value: any) {
        return this._state[prop] = value;
    }

    private _clone(object: InternalState) {
        return object;
    }
}