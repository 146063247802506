import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/_auth/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Output("onCancel") onCancelEvent = new EventEmitter<any>();

  resetPwdForm: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.resetPwdForm = this.fb.group({
      username: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      code: ['', Validators.compose([Validators.required])],
      password: [],
      confirmPassword: []
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });    
  }

  send2FACode() {
    let user = this.resetPwdForm.value;
    this.authService.sendCodeEmailVerify(user.username, user.email).subscribe(resp => {
      this.toastr.success("Verification code sent on your email.");
    }, err => {
      this.toastr.error(err.error.message, "");
    });
  }

  submit() {
    if (this.resetPwdForm.invalid) {
      return;
    }
    let value = this.resetPwdForm.value;
    this.authService.resetUserPwd(value.username, value.password, value.code).subscribe(resp => {
      this.toastr.success("Password has been reset. Now please login with new password.");
      this.onCancelEvent.emit(undefined);
    }, err => {
      this.toastr.warning("Code verfication failed.", "");
    });
  }

  getUsernameByEmail() {
    if (this.resetPwdForm.value.email) {
      
      this.authService.getUserInfoByEmail(this.resetPwdForm.value.email).subscribe(resp => {
        this.resetPwdForm.controls.username.patchValue(resp.username);
      }, err => {
        this.toastr.warning("NO user found with this email.");  
      });
    } else {
      this.toastr.warning("Please put email first to get username");
    }   
  }

  cancel() {
    this.onCancelEvent.emit(undefined);
  }

  hasError(controlName: string, validationType: string): boolean {
		const control = this.resetPwdForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
  }

}


class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl) {
		const password = control.get('password').value;

		const confirmPassword = control.get('confirmPassword').value;

		if (password !== confirmPassword) {
			control.get('confirmPassword').setErrors({ConfirmPassword: true});
		} else {
      control.get('confirmPassword').setErrors(null);
			return null;
		}
	}
}
