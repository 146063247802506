import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/core/_services/notifications.service';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';

@Component({
  selector: 'app-common-notifications',
  templateUrl: './common-notifications.component.html',
  styleUrls: ['./common-notifications.component.css']
})
export class CommonNotificationsComponent implements OnInit {

  username: string;
  notifications: Notification[];

  constructor(private appState: AppState, private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.username = this.appState.get(AppConstants.USERNAME);
    this.notificationsService.getNotificationsForUser(this.username).subscribe(resp => {
      let response = resp as NotificationsResponse;
      this.notifications = response.notifications;
    });
  }

  messageSentTime(notification: Notification) {
    if (!notification.sentOn) return '';
    let date: Date = new Date(notification.sentOn);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

}

class Notification {
  id: number;
  title: string;
  message: string;
  notifType: string;
  sentOn: string;
}

class NotificationsResponse {
  total: number;
  size: number;
  page: number;
  notifications: Notification[];
}