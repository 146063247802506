import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterCustomerComponent } from './register-customer/register-customer.component';
import { RegisterPartnerComponent } from './register-partner/register-partner.component';
import { VerifyEmailComponent } from './verify-email/verify.email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


@NgModule({
  declarations: [ LoginComponent, RegisterCustomerComponent, RegisterPartnerComponent, VerifyEmailComponent, ResetPasswordComponent ],
  imports: [
    CommonModule,
    FormsModule,
		ReactiveFormsModule
  ],
  exports: [ LoginComponent, RegisterCustomerComponent, RegisterPartnerComponent, VerifyEmailComponent, ResetPasswordComponent]
})
export class AuthCommonModule { }
