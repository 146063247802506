import { Injectable } from '@angular/core';
import { AppState } from '../../_store/app.state';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConstants } from '../../_utils/apiconstants';
import { TokenResponse } from '../../_models/token.response';
import { RegisterUser } from '../../_models/register.user';

@Injectable()
export class AuthService {

    private restUrl: string;

    constructor(private http: HttpClient, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL);
    }

    // Authentication/Authorization
    login(username: string, password: string): Observable<TokenResponse> {
        let url = this.restUrl + ApiConstants.AUTHENTICATE;
        let request = { username: username, password: password };
        return this.http.post<TokenResponse>(url, request);
    }

    loginWithGoogleToken(email: string, token: string): Observable<TokenResponse> {
        let url = this.restUrl + '/authenticate-via-gtoken';
        let request = { username: email, password: token };
        return this.http.post<TokenResponse>(url, request);
    }

    loginWithFacebookToken(token: string): Observable<TokenResponse> {
        let url = this.restUrl + '/authenticate-via-fbtoken';
        let request = { username: 'dummy', password: token };
        return this.http.post<TokenResponse>(url, request);
    }
   
    getUserDetails(username: string): Observable<RegisterUser> {
        let url = this.restUrl + ApiConstants.REGISTER + '/' + username;
        return this.http.get<RegisterUser>(url);
    }

    sendCodeEmailVerify(username: string, email: string):Observable<any> {
        let url = this.restUrl + ApiConstants.VERIFY;
        let request = { username: username, emailId: email };
        return this.http.put<TokenResponse>(url, request);
    }

    verifyCodeEmailVerify(username: string, twoFaCode: string):Observable<any> {
        let url = this.restUrl + ApiConstants.VERIFY_CODE;
        let request = { username: username, twoFaCode: twoFaCode };
        return this.http.put<TokenResponse>(url, request);
    }

    updateUserBasicInfo<T>(username: string, firstname: string, lastname: string): Observable<T> {
        let url = this.restUrl + ApiConstants.REGISTER + '/' + username;
        let request = {firstname: firstname, lastname: lastname};
        return this.http.put<T>(url, request);
    }

    updateUserPwd<T>(username: string, password: string): Observable<T> {
        let url = this.restUrl + ApiConstants.UPDATE_PWD;
        let request = {username: username, password: password};
        return this.http.put<T>(url, request);
    }

    resetUserPwd<T>(username: string, password: string, tfaCode: string): Observable<any> {
        let url = this.restUrl + ApiConstants.RESET_PWD;
        let request = {username: username, password: password, tfa_code: tfaCode};
        return this.http.put(url, request);
    }

    getUserInfoByEmail(email: string): Observable<any> {
        let url = this.restUrl + '/get-user-info?email=' + email;
        return this.http.get(url);
    }
}