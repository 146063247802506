import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location,LocationStrategy,PathLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../../../core/_utils/appconstants';
import { AuthService } from '../../../core/_auth/services/auth.service';
import { RegisterUser } from 'src/app/core/_models/register.user';
import { AppState } from '../../../core/_store/app.state';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
  // providers: [
  //   Location,
  //   { provide: LocationStrategy, useClass: PathLocationStrategy }
  // ]
})
export class BaseComponent implements OnInit {

  fullName: string;
  currentUser:RegisterUser
  isCustomer: boolean = false;

  constructor(private router: Router, private cookieService: CookieService, private authService: AuthService, private appState: AppState, private location: Location) { }

  ngOnInit(): void {
    let token = this.cookieService.get(AppConstants.COOKIE_TOKEN);
    let username = this.cookieService.get(AppConstants.USERNAME);
    let userId = this.cookieService.get(AppConstants.USER_ID);
    if (token) {

      this.appState.set(AppConstants.COOKIE_TOKEN, token);
      this.appState.set(AppConstants.USERNAME, username);
      this.appState.set(AppConstants.USER_ID, userId);    

      this.authService.getUserDetails(username).subscribe(resp => {
        this.currentUser = resp;
        this.fullName = this.currentUser.firstname + ' ' + this.currentUser.lastname;       
        this.appState.set(AppConstants.USER_FULLNAME, this.fullName);
        if (this.currentUser.role) {
          this.appState.set(AppConstants.USER_ROLE, this.currentUser.role[0]);
        }

        // TODO: check user role for navigation
        if (this.currentUser.role[0].roleName == 'SITE_PARTNER') {
          this.isCustomer = false;
          // this.router.navigateByUrl('/partner');
        } else if (this.currentUser.role[0].roleName == 'SITE_USER') {
          this.isCustomer = true;
          // this.router.navigateByUrl('/customer');
        }
      
      }, err => {
        console.log(err);
      });

    } else {
      this.isCustomer = true;
      // this.router.navigateByUrl('/');
    }
  }

  onUserLogin(userRole: any) {
    if (userRole == 'SITE_PARTNER') {
      this.isCustomer = false;
    } else if (userRole == 'SITE_USER') {
      this.isCustomer = true;
    }
  }

  // isPartnerHeader(): boolean{
  //   return this.location.path().indexOf('/partner') > -1;
  // }
}
