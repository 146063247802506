// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
//
import { AppConstants } from '../../_utils/appconstants';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {


	constructor(private cookieService: CookieService, private router: Router) {

	}

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		let token = this.cookieService.get(AppConstants.COOKIE_TOKEN);
		if (token) {
			request = request.clone({
				setHeaders: {				
					Authorization: `Bearer ` + token
				}
			});	
		}		
		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {

					}
				},
				error => {
					if (error.status == 401) {
						this.router.navigateByUrl("/");
						this.cookieService.deleteAll("/");
					}
				}
			)
		);
	}
}
