import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { CouponParameters } from '../_models/coupon-parameters';

@Injectable()
export class CouponService {
  restUrl: string;

  constructor(private http: HttpClient, private appState: AppState) {
    this.restUrl = this.appState.get(ApiConstants.REST_URL);
  }

  public generateCouponCode(partnerId: number): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      ApiConstants.RANDOM_CODE +
      '?partnerId=' +
      partnerId;
    return this.http.get(url);
  }

  public createCoupon(
    username: any,
    formData: any,
    body: any
  ): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS;
    const reqHeader: Object = {
      responseType: 'json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
    };
    console.log(reqHeader, 'reqHeader....');
    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    formData.append('username', username);
    formData.append('jsondata', JSON.stringify(body));
    return this.http.post(url, formData, reqHeader);
  }

  public updateCoupon(
    username: any,
    formData: any,
    body: any
  ): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS;
    const reqHeader: Object = {
      responseType: 'json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
    };
    console.log(reqHeader, 'reqHeader....');
    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    formData.append('username', username);
    formData.append('jsondata', JSON.stringify(body));
    return this.http.put(url, formData, reqHeader);
  }

  public listUsStates(): Observable<any> {
    let url = this.restUrl + ApiConstants.STATES_OF_US;
    return this.http.get(url);
  }

  brandSearch(text: string): Observable<any> {
    let url = this.restUrl + ApiConstants.BRANDS_SEARCH + '?text=' + text;
    return this.http.get(url);
  }

  public listPartnerCoupons(
    username: string,
    page: number,
    size: number
  ): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      '?username=' +
      username +
      '&page=' +
      page +
      '&size=' +
      size;
    return this.http.get(url);
  }

  public disableCoupon(username: string, couponId: number): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      '/' +
      couponId +
      '/disable?username=' +
      username;
    return this.http.put(url, {});
  }

  public enableCoupon(username: string, couponId: number): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      '/' +
      couponId +
      '/enable?username=' +
      username;
    return this.http.put(url, {});
  }

  public deleteCoupon(username: string, couponId: number): Observable<any> {
    let url = 
      this.restUrl +
      ApiConstants.COUPONS +
      '/' +
      couponId +
      '?username=' +
      username;
    return this.http.delete(url);
  }

  public renewCoupon(username: string, couponId: number): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      '/' +
      couponId +
      '/renew?username=' +
      username;
    return this.http.put(url, {});
  }

  public useCoupon(customerId: number, couponId: number) {
    let url = this.restUrl +
      ApiConstants.COUPONS +
      '/' + couponId +
      '/use' +
      '?customerId=' + customerId;
    return this.http.put(url, {});
  }

  public getPartnerCouponUsage(partnerId: number): Observable<any> {
    let url = this.restUrl +
      ApiConstants.COUPONS +
      '/usage?partnerId=' + partnerId;
    return this.http.get(url); 
  }

  public getCouponOffers(page: number, size: number, state: string, zipcode: string, category: string, partnerId: number, search: string): Observable<any> {
    let url =
      this.restUrl +
      ApiConstants.COUPONS +
      ApiConstants.COUPONS_OFFERS +
      '?page=' + page +
      '&size=' + size +
      '&state=' + state +
      '&zipCode=' + zipcode +
      '&category=' + category +
      '&partnerId=' + partnerId +
      '&search=' + search;
    return this.http.get(url);
  }

  MultiSearchCoupons(couponParameters: CouponParameters): Observable<any>{ 
    let url = this.restUrl + ApiConstants.COUPONS + ApiConstants.MULTI_COUPONS;
    const reqHeader: Object = {
      responseType: 'json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    
    return this.http.post(url, couponParameters, reqHeader);
  }

  public getCategories(): Observable<any> {
    let url = this.restUrl + ApiConstants.CATEGORY;
    return this.http.get(url);
  }

  public saveCustomerCoupon(couponId: number, username: string): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS 
      + '/customer-saved'
      + '?couponId=' + couponId
      + '&username=' + username;
    return this.http.post(url, {});
  }

  public getCustomerSavedCoupons(username: string): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS 
      + '/customer-saved'
      + '?username=' + username;
    return this.http.get(url);
  }

  public deleteCustomerSavedCoupon(couponId: number, username: string): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS 
      + '/customer-saved'
      + '?couponId=' + couponId
      + '&username=' + username;
    return this.http.delete(url);
  }

  public incrementCouponCountForAnalytics(couponId: number): Observable<any> {
    let url = this.restUrl + ApiConstants.COUPONS +'/'+couponId+ '/increment-usage'  
    return this.http.post(url, {});
  }
}
