export class ApiConstants {
    public static readonly REST_URL = "REST_URL";
    public static readonly GOOGLE_API_KEY = "google_api_key"; // used for maps API
    public static readonly GOOGLE_CLIENT_ID = "google_client_id"; // used for Sign IN with Google
    public static readonly FACEBOOK_APP_ID = "facebook_app_id"; // used for Sign IN with Facebook
    public static readonly API = '/api';
    public static readonly PAYMENT_WEBURL = "payment_weburl";

    public static readonly AUTHENTICATE = '/authenticate';        
    public static readonly VERIFY = '/verify';
    public static readonly VERIFY_CODE = '/verifyCode';
    public static readonly REGISTER = '/register';
    public static readonly REGISTER_PARTNER = '/partner';
    public static readonly UPDATE_PWD = '/regpwd';
    public static readonly RESET_PWD = '/resetpwd';

    public static readonly CATEGORY = '/category';
    public static readonly CATEGORY_NAMES = '/onlycategory';
    public static readonly SUBCATEGORY = '/subCategory';
    public static readonly SUBCAT_BY_CAT = '/subCategory/byCatName';
    public static readonly DEAL = '/deal';
    public static readonly ALLDEALS = '/deal/allDeals';
    public static readonly SEARCH_DEALS = '/deal/search';
    public static readonly MULTI_SEARCH_DEALS = '/deal/multisearch';
    public static readonly SEARCH_KEYWORDS = '/keywords-search';
    public static readonly BY_KEYWORD = '/by-keyword';
    public static readonly PUBLISH = '/publish';
    public static readonly UNPUBLISH = '/unPublish';
    public static readonly ACTIVATE = '/activate';
    public static readonly DEACTIVATE = '/deactivate';
    public static readonly BY_ID = '/byId';
    public static readonly BY_OWNER = '/byOwner';
    public static readonly BY_CATEGORY = '/byCategory';

    public static readonly PRODUCT = '/product';
    public static readonly STORE_LOCATION = '/storeLocation';
    public static readonly STORE_BZ_HOURS = '/storeBizHrs';
    public static readonly DEAL_PHOTO_UPLOAD = '/dealPhotoUpload';
    public static readonly AGREEMENT = '/agreement';

    public static readonly STORE_LOCATION_SELLER = '/storeLocationForSeller';

    public static readonly MESSAGE = '/message';
    public static readonly MESSAGE_THREAD = '/msgThread';
    public static readonly MESSAGES_CONSUMER = '/consumerMsgs';
    public static readonly MESSAGES_PROVIDER = '/providerMsgs';
    public static readonly ATTACHMENT = '/attachment';
    public static readonly MSG_CONSUMER_READ = '/msgConRead';
    public static readonly MSG_PROVIDER_READ = '/msgProRead';
    public static readonly CHECK_EXISTING_CHAT = '/check-existing-chat';

    public static readonly NOTIFICATIONS = '/notifications';

    public static readonly CART = '/cart';
    public static readonly CHECK_ADD_TOCART = '/check/add-to-cart';
    public static readonly ORDER = '/order'
    public static readonly ADDRESS = '/address';

    public static readonly ORDER_UPDATE_PACKED = '/update-status/packed';
    public static readonly ORDER_UPDATE_SHIPPED = '/update-status/shipped';
    public static readonly ORDER_UPDATE_DELIVERED = '/update-status/delivered';
    public static readonly ORDER_UPDATE_CANCELLED = '/update-status/cancelled';
    public static readonly ORDER_UPDATE_RETURNED = '/update-status/returned';
    public static readonly ORDER_UPDATE_RETURN_REQUESTED = '/update-status/return-requested';

    public static readonly RATING = '/rating';

    public static readonly COUPONS = '/coupons';
    public static readonly MULTI_COUPONS = "/multi/offers";
    public static readonly RANDOM_CODE = '/random-code';
    public static readonly COUPONS_OFFERS = '/offers';

    public static readonly STATES_OF_US = '/states';
    public static readonly BRANDS_SEARCH = '/brands-search';

    public static readonly USER_QUERY = '/user-query';

    public static readonly UPDATE_PHOTO_BRAND = '/registerPhoto';

    public static readonly UPDATE_PHOTO_SELLER_PROFILE = '/registerPhoto-SellerProfile';

    public static readonly SELLER_BY_BRAND = '/sellerByBrand';

    public static readonly COUNTRIES = '/getAllCountries';

    public static readonly ALL_STORES = "/getAllBusinessData";

    public static readonly EXCLUSIVE_STORES = "/getAllExclusiveStores";
}