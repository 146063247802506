import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
  @Input() caller: string; 

  ngOnInit(){

  }
  IsDeal(): boolean{
    if(this.caller == "dealComponent"){
      return true;
    }  
    return false;
  }
}
