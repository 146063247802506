<section class="top_deals-wrapper" *ngIf="coupons.length > 0">
  <div class="container w-100">
    <div class="row">
      <div class="col-12">
        <h2 class="primary-heading text-center">
          Local <span class="orange-text">Coupons</span>
        </h2>
        <owl-carousel-o [options]="dealCaraouselOptions">
          <ng-container *ngFor="let coupon of coupons">
            <ng-template carouselSlide>
              <div class="card">
                <div style="height: 210px; background-color: #556576; border-radius: 5px 5px 0 0;">
                  <img class="card-img-top" 
                  [src]="coupon.brandLogo" [alt]="coupon.brandName"
                  style="height: 100%;" 
                  />
                </div>
                <div class="card-body">
                  <div class="card-body-topsection">
                    <span class="card-body-title">{{ coupon.brandName }}</span>
                    <span class="btn-success-green">Exclusive</span>
                  </div>

                  <h5 class="deal-title" style="
                      min-height: 55px;
                      max-height: 70px;
                      overflow: hidden;
                      font-weight: bold;
                      margin-top: 5px;
                    ">
                    {{ coupon.shortTitle }}
                  </h5>

                  <p class="deal-description" style="min-height: 55px; max-height: 55px; overflow: hidden">
                    {{ coupon.longDescription }}
                  </p>

                  <div class="deal-expDate-wrapper">
                    <span class="deal-expDate-span">Expires on {{ coupon.validUpTo | date }}</span>
                  </div>

                  <div class="custom-btn-wrapper" style="top: 40px">
                    <button type="button" class="btn custom-btn-sml-orange" title="Get This Coupon"
                      (click)="getThisCoupon()">
                      Show Coupon Code
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>

        <div class="custom-btn-wrapper-lrg">
          <button class="btn custom-btn-large-orange">
            <span routerLink="/customer/coupons-browse">View More Coupons</span>
            <div class="icon-wrapper">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>