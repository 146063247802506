<div class="popular_stores-wrapper">
  <div class="popular_stores-inner">
    <div class="container">
      <div class="popular_stores-header section-header">
        <h2 class="primary-heading text-center">Popular <span class="orange-text">Stores</span></h2>
      </div>
      <div class="popular_stores-body">
        <div class="popular_stores-contentWrap featuredStoreBox">
          <div class="featuredStore">
            <h3>Featured Store Of The Month</h3>
            <div class="featuredStoreLogoWrap">
              <img src="../../../../assets/images/hostinger-img.png" alt="" />
            </div>
            <span>59 Coupons 39|Offers Available</span>
          </div>
        </div>
        <div class="popular_stores-contentWrap storesBoxes">
          <div *ngFor="let item of items" class="stores">
            <a [href]="item.linkUrl">
              <img [src]="item.imageUrl" alt="Image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
