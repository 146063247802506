import { Injectable } from "@angular/core";
import { Address } from "cluster";
import { Observable } from "rxjs";
import { AppState } from "../_store/app.state";
import { ApiConstants } from "../_utils/apiconstants";
import { DataService } from "./data.service";

@Injectable()
export class OrderService {
    
    restUrl: string;
    addressUrl: string;

    constructor(private dataServie: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL) + ApiConstants.ORDER;    
        this.addressUrl = this.appState.get(ApiConstants.REST_URL) + ApiConstants.ADDRESS;    
    }

    public createOrder(request: any): Observable<any> {
        return this.dataServie.create(this.restUrl, request, null);
    }

    public updateOrder(request: any): Observable<any> {
        return this.dataServie.update(this.restUrl, request);
    }

    public confirmOrder(request: any): Observable<any> {
        return this.dataServie.create(this.restUrl + '/place/success', request, null);
    }

    public confirmOrderFailed(request: any): Observable<any> {
        return this.dataServie.create(this.restUrl + '/place/fail', request, null);
    }

    public getOrderByCart(cartId: number): Observable<any> {
        let url = this.restUrl + '/bycart/' + cartId;
        return this.dataServie.get(url);
    }

    public getOrder(id: number): Observable<any> {
        return this.dataServie.get(this.restUrl + '/' + id);
    }

    public getOrdersForConsumer(username: string, page: number, size: number): Observable<any> {
        let url = this.restUrl + '/byConsumer/' + username + '?page=' + page + '&size=' + size;
        return this.dataServie.get(url);
    }

    public getOrdersForProvider(username: string, statusFilter: string, page: number, size: number): Observable<any> {
        let url = this.restUrl + '/byProvider/' + username + '?status=' + statusFilter
            + '&page=' + page + '&size=' + size;
        return this.dataServie.get(url);
    }

    public getCustomerAddressList(username: string): Observable<any> {
        return this.dataServie.get(this.addressUrl + '/' + username);
    }

    public createCustomerAddress(username: string, address: Address): Observable<any> {
        return this.dataServie.create(this.addressUrl + '/' + username, address, null);
    }

    public updateCustomerAddress(username: string, address: Address): Observable<any> {
        return this.dataServie.update(this.addressUrl + '/' + username, address);
    }

    public deleteCustomerAddress(id: number): Observable<any> {
        let url = this.addressUrl + '/' + id;
        return this.dataServie.delete(url);
    }

    public updateOrderStatusPacked(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_PACKED;
        return this.dataServie.update(url, null);
    }

    public updateOrderStatusShipped(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_SHIPPED;
        return this.dataServie.update(url, null);
    }

    public updateOrderStatusDelivered(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_DELIVERED;
        return this.dataServie.update(url, null);
    }

    public updateOrderStatusCancelled(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_CANCELLED;
        return this.dataServie.update(url, null);
    }

    public updateOrderStatusReturnRequest(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_RETURN_REQUESTED;
        return this.dataServie.update(url, null);
    }

    public updateOrderStatusReturned(id: number): Observable<any> {
        let url = this.restUrl + '/' + id + ApiConstants.ORDER_UPDATE_RETURNED;
        return this.dataServie.update(url, null);
    }

    public payAndConfirmOrder(request: any): Observable<any> {
        return this.dataServie.create(this.restUrl + '/pay', request, null);
    }

    public getTokenForPayment(orderId: number): Observable<any> {
        return this.dataServie.get(this.restUrl + '/token-for-payment/' + orderId);
    }

    public requestForRefundOfOrder(orderId: number, request: any) {
        return this.dataServie.create(this.restUrl + '/' + orderId + '/refund-to-customer', request, null);
    }

    public getOrderStatsForPartner(username: string): Observable<any> {
        let url = this.restUrl + '/' + 'orders-stats?partnerUsername=' + username;
        return this.dataServie.get(url);
    }
}