
export class Message {
    msgThreadId: number;
    messageId: number;
    consumerName: string;
    providerName: string;
    senderName: string;
    message: string;
    messageRead: boolean;
    created_DT: string;
    isSent: boolean;
}