<header class="header_main-wrapper" [ngClass]="{ fixedHeader: isfixedHeader }">
  <!-- First Navbar -->
  <div class="header_main-wrapper-inner">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container navbar-container">
        <div class="navbar-logoWrap">
          <a class="navbar-brand" *ngIf="login_state" routerLink="/customer" title="Locoldeal">
            <img class="logo" src="assets/images/logos/locoldeal-logo.png" alt="Logo" />
          </a>
          <a class="navbar-brand" *ngIf="!login_state" href="/" title="Locoldeal">
            <img class="logo" src="assets/images/logos/locoldeal-logo.png" alt="Logo" />
          </a>
        </div>
        <div class="navbar-btnsWrap">
          <ng-container *ngIf="login_state && screenWidth <= 991">
            <app-header-customer-profile></app-header-customer-profile>
          </ng-container>
          <ng-container *ngIf="screenWidth <= 991">
            <div class="dropdown search-toggleWrap">
              <button class="dropdown-toggle search-toggle" type="button" id="dropSearchButton" data-toggle="modal" data-target="#searchModal"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-search"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropSearchButton">
                <app-header-search></app-header-search>
              </div>
            </div>
          </ng-container>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <div class="close-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-lg"
                viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </button>
        </div>

        <!-- <div class="collapse navbar-collapse" id="navbarContent">
          <div class="navbar-contentwrap"> -->

        <ng-container *ngIf="screenWidth > 991">
          <app-header-search></app-header-search>
        </ng-container>

        <div class="header-btnsWrap" *ngIf="!login_state && screenWidth > 991" style="display: flex; gap: 15px">
          <button class="btn custom-btn-white light" type="button" data-toggle="modal" data-target="#panel7"
            title="Login" class="nav-link {{ activeTab.login }}">
            Log in
          </button>
          <button class="btn custom-btn-white light nav-link" id="navbarSignUpDropdown" data-toggle="dropdown"
            aria-haspopup="true" role="button" title="Sign Up">
            Sign Up
          </button> 
          <div class="dropdown-menu" aria-labelledby="navbarSignUpDropdown">
            <a class="dropdown-item" type="button" (click)="registerAsCustomer()" data-toggle="modal"
              data-target="#modalSignupForm" title="Sign up" #AsUserSignUpButton>
              As a User
            </a>
            <a class="dropdown-item" routerLink="/business/signup">
              As a Business
            </a>
          </div>
        </div>

        <ng-container *ngIf="login_state && screenWidth > 991">
          <app-header-customer-profile></app-header-customer-profile>
        </ng-container>
        <!-- </div>
        </div> -->
      </div>
    </nav>
  </div>

  <div class="header_sub-wrapper-inner">
    <!-- <ul>
      <li> All </li>
      <li> Deals of the Day </li>
      <li> Coupons </li>
      <li>  </li>
      <li> Deals of the Day </li>
      <li> Deals of the Day </li>
    </ul> -->
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="collapse navbar-collapse" id="navbarNav">
      <!-- Mobile layout -->
       <ng-container *ngIf="screenWidth <= 991">
          <app-mobile-navigation [categories]="dealCategories" [login_state] = "login_state"></app-mobile-navigation>
       </ng-container>

       <ng-container *ngIf="screenWidth > 991">
          <ul class="navbar-nav">   
            <li class="nav-item active">
              <button class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" title="All" (click)="setActiveCategory(-1)">
                <i class="fa fa-bars mr-1"></i> ALL
                <span class="sr-only">(current)</span>
              </button>
              <div class="dropdown-menu" [ngClass]="isActiveCategory(-1) ? 'w-34' : ''" aria-labelledby="navbarDropdown"
                id="navbarDropdownLayover" #navbarDropdownLayover>
                <!-- <button type="button" id="closeCategoryMenu" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button> -->
                <div class="header_categories-listWrap">
                  <div class="header_categories-listWrap-inner">
                    <ul class="list-group">
                      <li class="list-group-item" [ngClass]="isActiveCategory(1) ? 'activeCategoryTab' : ''">
                        <a routerLink="/customer/product-search" class="list-group-link"
                          (mouseenter)="showCategoryMenu(1)" title="Deals Of The Day">
                          <span> Local deals and Offers </span>
                          <!-- <i class="fa fa-regular fa-angle-right"></i> -->
                        </a>
                      </li>
                      <li class="list-group-item" [ngClass]="isActiveCategory(2) ? 'activeCategoryTab' : ''">
                        <a routerLink="/customer/coupons-browse" class="list-group-link" title="Coupons"
                          (mouseenter)="showCategoryMenu(2)">
                          <span> Coupons </span>
                          <!-- <i class="fa fa-regular fa-angle-right"></i> -->
                        </a>
                      </li>
                    </ul>
                    <!-- <button class="btn custom-btn text-left w-100" style="font-size: 18px">
                      Customer services
                    </button> -->
                    <!-- <button class="btn custom-btn text-left w-100">
                      Sign In
                    </button> -->
                  </div>
  
                  <div class="header_categories-listWrap-inner" *ngIf="selectedContent === 1">
                    <div class="d-flex body-top-wrapper">
                      <h5>Local deals and offers</h5>
                      <!-- <button class="btn custom-btn-orange ml-3 light" type="button" title="ViewAll">
                        View all
                      </button> -->
                    </div>
  
                    <ul class="header_categories-list" id="catListUL">
                      <li *ngFor="let cat of dealCategories" class="header_categories-item">
                        <a class="header_categories-link" (click)="
                            navigateToSearch(selectedContent, cat.categoryName)
                          " title="{{ cat.categoryName }}">
                          <span>
                            {{ cat.categoryName }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
  
                  <div class="header_categories-listWrap-inner" *ngIf="selectedContent === 2">
                    <div class="d-flex body-top-wrapper">
                      <h5>Coupons</h5>
                      <!-- <button class="btn custom-btn-orange ml-3 light" type="button" title="ViewAll">
                        View all
                      </button> -->
                    </div>
  
                    <ul class="header_categories-list" id="catListUL">
                      <li *ngFor="let cat of dealCategories" class="header_categories-item">
                        <a class="header_categories-link" (click)="
                            navigateToSearch(selectedContent, cat.categoryName)
                          " title="{{ cat.categoryName }}">
                          <span>
                            {{ cat.categoryName }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/product-search">Deals Of The Day</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/store-list">Stores</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/customer/coupons-browse">Coupons</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Categories</a>
            </li>
            <li class="nav-item" *ngIf="!login_state">
              <a class="nav-link" routerLink="/business/signin">Business login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://locoldeal.com/blog">Blog</a>
            </li>
          </ul>
       </ng-container>
      </div>

    </nav>
  </div>
</header>

<!--Modal: Login Form-->
<div class="modal fade bd-example-modal-lg login_signup-modal" id="panel7" tabindex="-1" role="dialog"
  aria-labelledby="myModalSignInLabel" aria-hidden="true" #modalLoginForm>
  <div class="modal-dialog modal-lg cascading-modal" role="document">
    <!--Content-->
    <div class="modal-content">
      <div style="display: flex; flex-direction: row">
        <div style="flex: 1">
          <span class="close-btn"><button type="button" #closeModal class="btn btn-outline-info" data-dismiss="modal">
              <i class="fa fa-close"></i></button></span>

          <!--Modal cascading tabs-->
          <div class="modal-c-tabs">
            <!-- Tab panels -->
            <div class="tab-content">
              <div class="tab-pane {{ activeTab.login }}" id="panel7" role="tabpanel">
                <!--Body-->
                <div class="modal-body mb-1">
                  <!-- LOGIN FORM -->
                  <div [hidden]="showResetPwd">
                    <app-login (onForgotPwdClick)="onForgotPwdClick()"
                      (onLoginSuccess)="onLoginSuccess($event)" (signUpButtonEvent)="signUpButtonClickEvent($event)"></app-login>
                  </div>
                  <div *ngIf="showResetPwd">
                    <app-reset-password (onCancel)="onResetPwdCancel()"></app-reset-password>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-img-container">
          <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
            ">
            <img style="width: 200px; height: auto; margin-top: 2rem" class="img-fluid"
              src="../../../../assets/images/logos/locoldeal-logo.png" alt="" />
          </div>
          <div class="modal-body mb-1" style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              padding: 0px;
            ">
            <img style="width: 400px; height: auto" class="img-fluid"
              src="../../../../assets/images/Login/LoginBanner.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!--Modal: Register Form-->
<div class="modal fade bd-example-modal-lg login_signup-modal" id="modalSignupForm" tabindex="-1" role="dialog"
  aria-labelledby="myModalSignUpLabel" aria-hidden="true" #modalSignUpForm>
  <div class="modal-dialog modal-lg cascading-modal" role="document">
    <!--Content-->
    <div class="modal-content">
      <div style="display: flex; flex-direction: row">
        <div style="flex: 1">
          <span class="close-btn"><button type="button" #closeModal class="btn btn-outline-info" data-dismiss="modal">
              <i class="fa fa-close"></i></button></span>

          <!--Modal cascading tabs-->
          <div class="modal-c-tabs">
            <!-- Tab panels -->
            <div class="tab-content">
              <div id="panel7" role="tabpanel">
                <div [hidden]="showVerifyEmail" class="modal-body mb-1">
                  <app-register-customer></app-register-customer>
                </div>

                <div *ngIf="showVerifyEmail" class="modal-body mb-1">
                  <app-verify-email (onVerifySuccess)="callbackOnVerified()"
                    (onVerifyCancelled)="callbackOnVerifyCancel()"></app-verify-email>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-img-container">
          <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
            ">
            <img style="width: 200px; height: auto; margin-top: 2rem" class="img-fluid"
              src="../../../../assets/images/logos/locoldeal-logo.png" alt="" />
          </div>
          <div class="modal-body mb-1" style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;         
              padding: 0px;
            ">
            <img style="width: 400px; height: auto" class="img-fluid"
              src="../../../../assets/images/Login/LoginBanner.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Search Modal Starts -->

<!-- Chat Window -->
<ng-container *ngIf="login_state && chatToggle">
  <app-customer-chat (close)="onChatClose($event)"></app-customer-chat>
</ng-container>

<ng-container *ngIf="login_state && notifWindowToggle">
  <app-common-notifications></app-common-notifications>
</ng-container>

<ng-container>
  <app-common-search-page></app-common-search-page>
</ng-container>