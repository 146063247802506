import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DealDetail } from 'src/app/core/_models/deal.detail';
import { DealsService } from 'src/app/core/_services/deals.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { CookieService } from 'ngx-cookie-service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  dealsOfTheDay1: DealDetail[] = [];
  dealsOfTheDay2: DealDetail[] = [];
  dealsUnder200: DealDetail[] = [];
  dealsYouMayLike: DealDetail[] = [];
  favBrandsCoupons: any[];
  allCoupons: any[];
  allBanners: any[];

  dealCaraouselOptions: OwlOptions;
  couponsCaraouselOptions: OwlOptions;
  allCouponsCaraouselOptions: OwlOptions;
  bannerCarauselOptions: OwlOptions;

  loginState: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private dealsService: DealsService,
    private appState: AppState,
    private cookieService: CookieService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.title.setTitle(
      'Discover Local Deals and Exciting Offers at LocoLDeal.com! | LocolDeal'
    );
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover a wide range of amazing discounts and promotions at LocoLDeal.com. Explore our website now and start saving on your favorite products and services. Visit LocoLDeal.com today',
      },
      // { name: 'author', content: 'talkingdotnet' },
      // { name: 'keywords', content: 'Angular, Meta Service' },
    ]);
    // this.meta.updateTag({
    //   name: 'description',
    //   content:
    //     'Discover a wide range of amazing discounts and promotions at LocoLDeal.com. Explore our website now and start saving on your favorite products and services. Visit LocoLDeal.com today',
    // });
  }

  ngOnInit(): void {
    // this.title.setTitle(
    //   'Discover Local Deals and Exciting Offers at LocoLDeal.com!'
    // );
    // this.meta.updateTag({
    //   name: 'description',
    //   content:
    //     'Discover a wide range of amazing discounts and promotions at LocoLDeal.com. Explore our website now and start saving on your favorite products and services. Visit LocoLDeal.com today',
    // });
    // this.meta.updateTag({
    //   property: 'og:title',
    //   content: 'Discover Local Deals and Exciting Offers at LocoLDeal.com!',
    // });
    // this.meta.updateTag({
    //   property: 'og:description',
    //   content:
    //     'Discover a wide range of amazing discounts and promotions at LocoLDeal.com. Explore our website now and start saving on your favorite products and services. Visit LocoLDeal.com today',
    // });
    // this.meta.updateTag({
    //   property: 'og:image',
    //   content: '/images/og-image.jpg',
    // });

    // TODO: handle ERROR events, duplicate register, OTP verification failed....
    if(this.cookieService.get(AppConstants.COOKIE_TOKEN)){
      if (this.cookieService.get(AppConstants.USERNAME)) {
        this.loginState = true;
      } else {
        this.loginState = false;
      }
    }
    this.dealsOfTheDay1 = this.dealsService.getDealsOfTheDay1();
    this.dealsOfTheDay2 = this.dealsService.getDealsOfTheDay2();
    this.dealsUnder200 = this.dealsService.getDealsUnder200();
    this.dealsYouMayLike = this.dealsService.getDealsYouMayLike();
    this.favBrandsCoupons = this.dealsService.getCouponsFavBrands();
    this.allCoupons = this.dealsService.getAllCoupons();
    this.allBanners = this.dealsService.getBanners();

    this.setAllCaraouselOptions();
  }

  setAllCaraouselOptions() {
    this.dealCaraouselOptions = {
      loop: true,
      dots: false,
      margin: 10,
      nav: true,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    };
    this.couponsCaraouselOptions = {
      loop: true,
      margin: 30,
      nav: true,
      navText: [
        '<i class="navigation fa fa-chevron-left"></i>',
        '<i class="navigation fa fa-chevron-right"></i>',
      ],
      dots: false,
      responsive: {
        0: {
          items: 3,
        },
        480: {
          items: 3,
        },
        576: {
          items: 4,
        },
        768: {
          items: 5,
        },
        1000: {
          items: 8,
        },
      },
    };
    this.allCouponsCaraouselOptions = {
      loop: true,
      margin: 40,
      nav: true,
      navText: ['', ''],
      dots: false,
      responsive: {
        0: {
          items: 4,
        },
        480: {
          items: 4,
        },
        576: {
          items: 6,
        },
        768: {
          items: 8,
        },
        1000: {
          items: 10,
        },
      },
    };

    this.bannerCarauselOptions = {
      loop: true,
      autoplay: true,
      items: 1,
      autoplayTimeout: 3000,
    };
  }

  browseCoupons() {
    this.router.navigateByUrl('/customer/coupons-browse');
  }

  calcDiscount(deal: DealDetail): number {
    let p1 = deal.products[0].normalPrice;
    let p2 = deal.products[0].dealPrice;
    let discount = p1 - p2;
    return discount;
  }



  ngOnDestroy() {}

  ngAfterViewInit() {
    // let atOptions = {
    // 'key' : 'cd8b1fdd3b6e9745fdcc229a00227b91',
		// 'format' : 'iframe',
		// 'height' : 200,
		// 'width' : 728,
		// 'params' : {}
    // };

    // const conf =  this.renderer.createElement('script');
    // var s = this.renderer.createElement("script");
    // s.type = "text/javascript";
    // s.src = "//www.topcreativeformat.com/cd8b1fdd3b6e9745fdcc229a00227b91/invoke.js";
    // conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

    // if (this.adDiv && this.adDiv.nativeElement) {
    //   this.renderer.appendChild(this.adDiv.nativeElement, conf);
    //   this.renderer.appendChild(this.adDiv.nativeElement, s);
    // }

    // let atOptions = {
    // 'key' : '53a618aeeaadbdcd2403dc79caa6d758',
		// 'format' : 'iframe',
		// 'height' : 50,
		// 'width' : 320,
		// 'params' : {}
    // };
  
    //   const conf = document.createElement('script')
    //   var s = document.createElement("script");
    //   s.type = "text/javascript";
    //   s.src = "//www.topcreativeformat.com/53a618aeeaadbdcd2403dc79caa6d758/invoke.js";
    //   conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
    //   this.adDiv?.nativeElement.append(conf);
    //   this.adDiv?.nativeElement.append(s);
  }
}
