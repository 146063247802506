import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  endpoint: string;

  constructor(private dataService: DataService, private appState: AppState) {
    this.endpoint = this.appState.get(ApiConstants.REST_URL) + ApiConstants.NOTIFICATIONS;    
  }

  public getNotificationsForUser(username: string): Observable<any> {
    let url = this.endpoint + '/' + username;
    return this.dataService.get(url);
  }
}
