import { Component, OnInit, OnDestroy, Renderer2, PLATFORM_ID, Inject, ElementRef } from '@angular/core';
import { DealDetail } from 'src/app/core/_models/deal.detail';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/core/_services/coupon.service';
import { Coupon } from 'src/app/core/_models/coupon';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';
import EmblaCarousel from 'embla-carousel';
import { generateDotBtns, selectDotBtn } from '../../theme/embla-carousal/dotbuttons';
import { disablePrevNextBtns, setupPrevNextBtns } from '../../theme/embla-carousal/prevNextButtons';
import { setupRTLDirectionIfNeeded } from '../../theme/embla-carousal/directionRTL';
import { isPlatformBrowser } from '@angular/common';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

@Component({
  selector: 'app-top-deals',
  templateUrl: './top-deals.component.html',
  styleUrls: ['./top-deals.component.scss'],
})
export class TopDealsComponent implements OnInit, OnDestroy {
  coupons: any[];
  groupedCoupons: any[];
  dealCaraouselOptions: OwlOptions;
  MINIMUM_COUPONS: number;
  private isBrowser: boolean;
  public embla: any;
  noImageAvailable: string;
  
  constructor(private appState: AppState,
    private toastr: ToastrService,
    private router: Router,
    private couponService: CouponService, 
    private renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object,
    private elem: ElementRef) {
      this.isBrowser = isPlatformBrowser(platformId);
    this.coupons = [];
    this.groupedCoupons = [];
    this.MINIMUM_COUPONS = 4;
    this.noImageAvailable = "../../../../assets/images/no-image.png";
  }

  ngOnInit(): void {    
    this.getAllCoupons();
  }

  ngAfterViewInit(): void {
    // this.initializeEmblaCarousal();
  }
  

  initializeEmblaCarousal() {
    setTimeout(() => {
      const wrap = this.renderer.selectRootElement('.embla-top-deals', true);
      
      if(wrap && this.isBrowser){
        const viewPort = wrap.querySelector('.embla__viewport')!
        const prevBtn = wrap.querySelector('.embla__button--prev');
        const nextBtn = wrap.querySelector('.embla__button--next');
        const dots = wrap.querySelector('.embla__dots');
        this.embla = EmblaCarousel(
          viewPort as HTMLElement,
          {
            align: 'start'
          },
          [WheelGesturesPlugin()]
        )
        
        // const dotsArray = generateDotBtns(dots, this.embla)
        // const setSelectedDotBtn = selectDotBtn(dotsArray, this.embla)
        const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, this.embla)
    
        setupPrevNextBtns(prevBtn, nextBtn, this.embla);
        // setupDotBtns(dotsArray, embla);
        // dotsArray.forEach((dotNode, i) => {
        //   dotNode.classList.add('embla__dot')
        //   dotNode.addEventListener('click', () => this.embla.scrollTo(i), false)
        // })
        // setupPrevNextBtns(prevBtn, nextBtn, this.embla);
        setupRTLDirectionIfNeeded(this.embla, wrap) // visit with query parameter (?rtl)
    
        // this.embla.on('select', setSelectedDotBtn)
        this.embla.on('select', disablePrevAndNextBtns)
        // this.embla.on('init', setSelectedDotBtn)
        this.embla.on('init', disablePrevAndNextBtns)
      }
    },1000);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  getAllCoupons() {
    this.couponService.getCouponOffers(0, 100, '', '', '', 0, '').subscribe(resp => {
      this.coupons = resp.coupons;

      // Group the coupons as per view
      for (let i = 0; i < this.coupons.length; i += 3) {
        const group = [this.coupons[i]];
        if (i + 1 < this.coupons.length) {
          group.push(this.coupons[i + 1]);
        }
        if (i + 2 < this.coupons.length) {
          group.push(this.coupons[i + 2]);
        }
        this.groupedCoupons.push(group);
      }
      console.warn(this.groupedCoupons);
      this.initializeEmblaCarousal();
    });
  }

  setAllCaraouselOptions() {
    this.dealCaraouselOptions = {
      loop: true,
      dots: false,
      margin: 16,
      nav: true,
      navSpeed: 700,
      navText: [    
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1400: {
          items: 5,
        },
      },
    };
  }
  calcDiscount(deal: DealDetail): number {
    let p1 = deal.products[0].normalPrice;
    let p2 = deal.products[0].dealPrice;
    let discount = p1 - p2;
    return discount;
  }
  truncate(value: string, length: number): string {
    return CommonsUtils.truncate(value, length);
  }
  
  getThisCoupon() {
    if (!this.loginState()) {
      window.scroll(0,0);
      this.toastr.error('To activate this Coupon you must sign in as consumer.', 'Sign In');
    } else {
      // do navigate to coupons browse
      this.router.navigateByUrl('/customer/coupons-browse');
    }
  }
  loginState() {
    if (this.appState.get(AppConstants.USERNAME)) {
      return true;
    } else {
      return false;
    }
  }

  dummyCoupon() {
    return {
      brandLogo: 'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png',
      brandName: 'Brand Name',
      category: 'Food',
      couponCode: 'CPN1000',
      discount: '',
      effectiveFrom: "2023-01-01T17:36:41",
      validUpTo: "2023-10-01T17:36:41",
      enabled: true,
      isFlatDiscount: false,
      longDescription: "flat discount on product",
      partnerId: 1,
      shortTitle:"Flat 2$ off"
    };
  }
  ngOnDestroy() {
    if(this.embla){
      this.embla.destroy();
    }
  }
}
