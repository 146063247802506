
export class Rating {
    ratingId: number;
    rating: number;
    ratingComment: string;
    ratingAuthor: string;

    productId: number;
    dealId: number;
    CREATED_DT: string;
}