<footer class="footer-wrapper">
  <div class="footer-top">
    <div class="container">
      <div class="footer-top-inner d-none">
        <div class="footer-top-left">
          <h3>Sign-up for the best deals on Locoldeal!</h3>
          <p>Realtime deals, promo codes and offer updates from all offers.</p>
        </div>
        <div class="footer-top-right">
          <div class="footer-top-inputwrap">
            <input
              type="text"
              class="form-control"
              placeholder="Enter email address"
            />
            <button type="submit" class="btn custom-btn" title="Submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-middle">
    <div class="container">
      <div class="footer-middle-inner">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-logowrap">
              <a  title="LocolDeal">
                <img
                  src="assets/images/logos/locoldeal-logo.png"
                  class="logo"
                  alt="logo"
                />
              </a>
            </div>
            <div class="d-flex justify-content-between mb-5">
              <a title="Playstore"
                href="https://play.google.com/store/apps/details?id=com.locoldeal&pcampaignid=web_share"
                target="_blank">
                <img src="assets/images/play-store.png" class="img-fluid" alt="Playstore" />
              </a>

              <a title="Appstore" class="ml-2">
                <img
                  src="assets/images/app-store.png"
                  class="img-fluid"
                  alt="Appstore"
                />
              </a>
            </div>

            <h5 class="footer-heading">Follow Us</h5>
            <ul class="social-list">
              <li class="social-item">
                <a target="_blank" href="https://twitter.com/LocolDeal" class="social-link" title="Twitter"
                  ><img src="assets/images/icons/twitter.svg" alt="Twitter"
                /></a>
              </li>
              <li class="social-item">
                <a target="_blank" href="https://facebook.com/locoldeal/" class="social-link" title="Facebook"
                  ><img src="assets/images/icons/facebook.svg" alt="Facebook"
                /></a>
              </li>
              <li class="social-item">
                <a target="_blank" href="https://instagram.com/locoldeal/" class="social-link" title="Instagram"
                  ><img src="assets/images/icons/instagram.svg" alt="Instagram"
                /></a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-8">
            <div class="footer-linkswrap pl-5">
              <div class="footer-linkswrap-inner">
                <h5 class="footer-heading">Company</h5>
                <ul class="footer-list">
                  <li class="footer-item">
                    <a routerLink="/customer/aboutus" class="footer-link" title="About Locoldeal"
                      >About Locoldeal.com</a
                    >
                  </li>
                  <li class="footer-item d-none">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Management Team"
                      >Management Team</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="/customer/terms-conditions" class="footer-link" title="Terms & Condition"
                      >Terms & Conditions</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="/customer/privacy-policy" class="footer-link" title="Privacy Policy"
                      >Privacy Policy</a
                    >
                  </li>
                  <li class="footer-item d-none">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Blogs"
                      >Blogs</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="/customer/faq-consumer" class="footer-link" title="Consumer FAQ"
                    > FAQ for Consumer</a>
                  </li>
                  <li class="footer-item">
                    <a routerLink="/customer/faq-service-provider" class="footer-link" title="Service Provider FAQ"
                    >Service Provider</a>            
                  </li>
                </ul>
              </div>
              <div class="footer-linkswrap-inner d-none">
                <h5 class="footer-heading">Business</h5>
                <ul class="footer-list">
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Deal Benefit"
                      >How Deal Benefit Your Business</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Promote Your Business"
                      >Promote Your Business</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Invest Oppurtunities"
                      >Invest Oppurtunities</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Create a Deal"
                      >Create a Deal</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Affiliates Disclosure"
                      >Affiliates Disclosure</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Our Brands"
                      >Our Brands</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- <p><a routerLink="/customer/aboutus">About Lokldeal.com</a></p>
            <p><a (click)="comingSoon()">Blog</a></p>
            <p><a (click)="comingSoon()">News</a></p>
            <p><a (click)="comingSoon()">Management Team</a></p>
            <h5 class="footer-heading">Business Oppurtunities</h5>
            <p><a (click)="comingSoon()">Promote Your Business</a></p>
            <p><a (click)="createDealCheck()">Create a Deal</a></p>
            <p><a (click)="comingSoon()">Invest Oppurtunities</a></p>
            <p><a (click)="comingSoon()">How Deal Benefit Your Business</a></p> -->
          </div>
          <div class="col-lg-3 col-md-8">
            <div class="footer-linkswrap">
              <div class="footer-linkswrap-inner">
                <h5 class="footer-heading">Contact Us</h5>

                <button _ngcontent-serverapp-c22="" 
                    class="btn custom-btn-large-orange">
                    <span _ngcontent-serverapp-c22="" routerLink="/customer/user-query">
                      Contact Us</span>
                </button>
                <!-- <ul class="footer-list">
                  <li class="footer-item">
                    <a  class="footer-link" title="About Locoldeal">Our mail.com
                    </a>
                  </li>
                  <li class="footer-item">
                    <a class="footer-link" title="About Locoldeal"
                      >+01 645***852</a>
                  </li>
                  <li class="footer-item">
                    <a class="footer-link" title="About Locoldeal"
                      >Building name street name block c street number street name postcode</a>
                  </li>
                </ul> -->
              </div>
              <div class="footer-linkswrap-inner d-none">
                <h5 class="footer-heading">Business</h5>
                <ul class="footer-list">
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Deal Benefit"
                      >How Deal Benefit Your Business</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Promote Your Business"
                      >Promote Your Business</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Invest Oppurtunities"
                      >Invest Oppurtunities</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Create a Deal"
                      >Create a Deal</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Affiliates Disclosure"
                      >Affiliates Disclosure</a
                    >
                  </li>
                  <li class="footer-item">
                    <a routerLink="#" (click)="comingSoon()" class="footer-link" title="Our Brands"
                      >Our Brands</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- <p><a routerLink="/customer/aboutus">About Lokldeal.com</a></p>
            <p><a (click)="comingSoon()">Blog</a></p>
            <p><a (click)="comingSoon()">News</a></p>
            <p><a (click)="comingSoon()">Management Team</a></p>
            <h5 class="footer-heading">Business Oppurtunities</h5>
            <p><a (click)="comingSoon()">Promote Your Business</a></p>
            <p><a (click)="createDealCheck()">Create a Deal</a></p>
            <p><a (click)="comingSoon()">Invest Oppurtunities</a></p>
            <p><a (click)="comingSoon()">How Deal Benefit Your Business</a></p> -->
          </div>
          <div class="col-lg-3 col-md-12 d-none d-lg-block">
            <h5 class="footer-heading">Get The Best Deals</h5>
            <img
              src="assets/images/get-best-deals-img.svg"
              class="w-100"
              alt="QR code"
            />
            <!-- <h5 class="footer-heading">Download The App</h5>
            <div class="footer-downloadAppWrap">
              <div class="footer-downloadAppWrap-inner">
                <img
                  src="assets/images/qr-code.png"
                  class="w-100"
                  alt="QR code"
                />
              </div>
              <div class="footer-downloadAppWrap-inner">
                <div class="downloadOnWrap">
                  <a href="#" class="download-link">
                    <img
                      src="assets/images/app-store-badge.png"
                      class="w-100"
                      alt="QR code"
                    />
                  </a>
                </div>
                <div class="downloadOnWrap">
                  <a href="#" class="download-link">
                    <img
                      src="assets/images/google-play-badge.png"
                      class="w-100"
                      alt="QR code"
                    />
                  </a>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom-inner">
        <p>
          Copyright © {{currentYear-1}}-{{currentYear}}, Gavelgo International Inc. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
