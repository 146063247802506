import { Component, Input } from "@angular/core";
import { BarRatingModule } from 'ngx-bar-rating';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'star-rating',
    standalone: true,
    imports: [BarRatingModule, FontAwesomeModule],
    templateUrl: './bar-rating-starts.html',
    styleUrls: ['./bar-rating-starts.scss'],
})

export class StarRating {
    readonly faStar = faStar;
    readonly faStarHalfAlt = faStarHalfAlt;
    readonly farStar = farStar;

    @Input("rating") rating: number;
    @Input("size") starSize: string;
}