import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import EmblaCarousel, { EmblaCarouselType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import { generateDotBtns, selectDotBtn, setupDotBtns } from '../../theme/embla-carousal/dotbuttons';
import { disablePrevNextBtns, setupPrevNextBtns } from '../../theme/embla-carousal/prevNextButtons';
import { setupRTLDirectionIfNeeded } from '../../theme/embla-carousal/directionRTL';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss'],
})



export class HomeBannerComponent implements OnInit, OnDestroy {
  private isBrowser: boolean;
  public embla: any;
  constructor(private router: Router, private renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.initializeEmblaCarousal();
  }

  ngOnDestroy(): void {
    if(this.embla){
      this.embla.destroy();
    }
  }

  initializeEmblaCarousal() {
    const wrap = this.renderer.selectRootElement('.embla', true);

    if(wrap && this.isBrowser){
      const viewPort = wrap.querySelector('.embla__viewport')!
      const prevBtn = wrap.querySelector('.embla__button--prev');
      const nextBtn = wrap.querySelector('.embla__button--next');
      const dots = wrap.querySelector('.embla__dots');
      
      this.embla = EmblaCarousel(
        viewPort as HTMLElement,
        {
          loop: true,
          skipSnaps: true,
        },
        [Autoplay()]
      )
      
      const dotsArray = generateDotBtns(dots, this.embla)
      const setSelectedDotBtn = selectDotBtn(dotsArray, this.embla)
      const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, this.embla)
  
      setupPrevNextBtns(prevBtn, nextBtn, this.embla)
      // setupDotBtns(dotsArray, embla);
      dotsArray.forEach((dotNode, i) => {
        dotNode.classList.add('embla__dot')
        dotNode.addEventListener('click', () => this.embla.scrollTo(i), false)
      })
      setupRTLDirectionIfNeeded(this.embla, wrap) // visit with query parameter (?rtl)
  
      this.embla.on('select', setSelectedDotBtn)
      this.embla.on('select', disablePrevAndNextBtns)
      this.embla.on('init', setSelectedDotBtn)
      this.embla.on('init', disablePrevAndNextBtns)
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  browseCoupons() {
    this.router.navigateByUrl('/customer/coupons-browse');
  }
}


function setupRadioButtons(radioButtonsArray: any, embla: EmblaCarouselType, disablePrevAndNextBtns: () => void) {
  throw new Error('Function not implemented.');
}

