import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { DataService } from './data.service';
import { MessageThread } from '../_models/message.thread';
import { Message } from '../_models/message';

@Injectable()
export class ChatMessageService {
    restUrl: string;

    constructor(private dataService: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL);    
    }

    public createNewMessageThread(message: MessageThread): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE;
        return this.dataService.create(url, message, null);
    }

    public getMessageThreadsConsumer(username: string): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + ApiConstants.MESSAGES_CONSUMER + '/' + username;
        return this.dataService.get(url);
    }

    public getMessageThreadsProvider(username: string): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + ApiConstants.MESSAGES_PROVIDER + '/' + username;
        return this.dataService.get(url);
    }

    public getMessagesForThread(id: number): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + '/msgById/' + id;
        return this.dataService.get(url);
    }

    public sendMessage(message: Message): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE_THREAD;
        return this.dataService.create(url, message, null);
    }

    public sendMessageAttachment(body: any): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE_THREAD + ApiConstants.ATTACHMENT + "/chat";
        return this.dataService.create(url, body, null);
    }

    public markMessageReadConsumer(messageId: string): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + ApiConstants.MSG_CONSUMER_READ + '/' + messageId;
        return this.dataService.update(url, null);
    }

    public markMessageReadProvider(messageId: string): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + ApiConstants.MSG_PROVIDER_READ + '/' + messageId;
        return this.dataService.update(url, null);
    }

    public getMessagesForDeal(dealId: number): Observable<any> {
        let url = this.restUrl + ApiConstants.MESSAGE + '/byDealId/' + dealId + '/list';
        return this.dataService.get(url);
    }

    public getNotificationsCount(userName: String, isPartner: boolean): Observable<any>{
        let url = this.restUrl + ApiConstants.MESSAGE + '/unread' + '?' + 'userName=' + userName + '&isPartner=' + isPartner;
        return this.dataService.get(url);
    }

    public checkExistingAvailableChatRoom(dealId: number, userName: String): Observable<any>{
        let url = this.restUrl + ApiConstants.CHECK_EXISTING_CHAT + `/${dealId}/${userName}`;
        return this.dataService.get(url);
    }
}