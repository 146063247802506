// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthAccountService } from '../../_services/auth.account.service';
import { AppState } from '../../_store/app.state';
import {AppConstants} from '../../_utils/appconstants';

@Injectable()
export class AuthGuard implements CanActivate {


    private userRoleDashboardConfig: any;
    private publicAccessPages: string[];

    constructor(private router: Router, private cookieService: CookieService, private appState: AppState, private authAccountService: AuthAccountService) {

        this.userRoleDashboardConfig = [
            { role: "ROLE_PARTNER", home: "/partner" }, 
            { role: "ROLE_CUSTOMER", home: "/customer" }
        ];
        this.publicAccessPages = ['deal-view', 'product-search', 'aboutus', 'coupons-browse', 'user-query', 'terms-conditions', 'privacy-policy', 'faq-consumer', 'seller-home', 'business/signup', 'business/signin', 'store-list'];        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let accessToken = this.cookieService.get(AppConstants.COOKIE_TOKEN);
        let userRole = this.appState.get(AppConstants.USER_ROLE);
        console.log('role ', userRole);
        if (accessToken) {            
            return true;
        } else if (this.matchFound(state.url, this.publicAccessPages)) {
            console.log('It is public access page', state.url);
            return true;
        } else {
            console.log('navigating to home');
            this.authAccountService.clearUserSession();
            this.router.navigateByUrl('/');
            return false;
        }
    }

    matchFound(pageUrl: string, accessible: string[]) {
        return accessible.filter(u => pageUrl.indexOf(u) > -1).length > 0
    }
}
