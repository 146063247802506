<section class="top_deals-wrapper" *ngIf="coupons.length > 0">
  <div class="container w-100">
    <div class="row">
      <div class="col-12">
        <h2 class="primary-heading text-center">
          Local <span class="orange-text">Coupons</span>
        </h2>
        <div class="embla embla-top-deals">
          <div class="embla__viewport">
            <div class="embla__container">
              <ng-container *ngFor="let obj of groupedCoupons; let i=index">
              <div class="embla__slide">
                  <div class="embla__slide__inner" *ngFor="let coupon of obj">
                    <div class="card">
                      <div style="height: 170px; border-radius: 5px 5px 0 0; object-fit: cover;">
                        <img class="card-img-top" [src]="coupon.brandLogo" [alt]="coupon.brandName"
                        (error)="coupon.brandLogo = noImageAvailable"
                          style="height: 100%;object-fit: contain;max-width: 100%;" />
                      </div>
                      <div class="card-body">
                        <div class="card-body-topsection">
                          <span class="card-body-title common-gradient-text w-fit-content">{{ coupon.brandName }}</span>
                          <span class="btn-success-green">Exclusive</span>
                        </div>

                        <h5 class="deal-title" style="
                          min-height: 37px;
                          max-height: 37px;
                          overflow: hidden;
                          font-weight: bold;
                          margin-top: 5px;
                          font-size: 16px;
                          display: -webkit-box;
                          -webkit-line-clamp: 2; /* Limits the content to 2 lines */
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: normal;
                        ">
                          {{ coupon.shortTitle }} - {{ coupon.longDescription }}
                        </h5>
                        <!-- <p class="deal-description mb-0" style="min-height: 40px; max-height: 40px; overflow: hidden">
                          {{ coupon.longDescription }}
                        </p> -->
                        <!-- <div class="deal-expDate-wrapper">
                          <span class="deal-expDate-span">Expires on {{ coupon.validUpTo | date }}</span>
                        </div> -->

                        <div class="custom-btn-wrapper" style="top: 40px">
                          <button type="button" class="btn custom-btn-sml-orange" title="Get This Coupon"
                            (click)="getThisCoupon()">
                            Show Coupon Code
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <button class="embla__button embla__button--prev" type="button">
            <div class="embla-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
          </button>
          <button class="embla__button embla__button--next" type="button">
            <div class="embla-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
          </button>
          <div class="custom-btn-wrapper-lrg">
            <button class="btn custom-btn-large-orange">
              <span routerLink="/customer/coupons-browse">View More Coupons</span>
              <div class="icon-wrapper">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>