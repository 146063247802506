import { Component, OnInit, OnDestroy } from '@angular/core';
import { DealDetail } from 'src/app/core/_models/deal.detail';
import { DealsService } from 'src/app/core/_services/deals.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';

@Component({
  selector: 'app-deals-of-day',
  templateUrl: './deals-of-day.component.html',
  styleUrls: ['./deals-of-day.component.scss'],
})
export class DealsOfDayComponent implements OnInit, OnDestroy {
  dealsOfTheDay: DealDetail[] = [];
  dealCaraouselOptions: OwlOptions;
  MINIMUM_DEALS: number;

  constructor(
    private dealsService: DealsService,
    private appState: AppState,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.MINIMUM_DEALS = 4; 
  }

  ngOnInit(): void {
    this.dealsOfTheDay = [];
    this.getAllDeals();
    this.setAllCaraouselOptions();
  }
  getAllDeals() {
    this.dealsService
      .searchDeals('', 1, 10000, -1, '', -1, '', 'PRICE_LOW_TO_HIGH', 0, 10000)
      .subscribe((resp) => {
        this.dealsOfTheDay = resp;
        const missing = this.MINIMUM_DEALS - this.dealsOfTheDay.length;
        // if (missing > 0) {
        //   let dummyDeals = this.dealsService.getDealsOfTheDay();
        //   for (var i = 0; i < missing; i++) {
        //     this.dealsOfTheDay.push(dummyDeals[i]);
        //   }
        // }
      });
  }

  setAllCaraouselOptions() {
    this.dealCaraouselOptions = {
      loop: true,
      margin: 16,
      dots: false,
      nav: true,
      navSpeed: 700,
      navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>',
      ],  
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1400: {
          items: 5,
        },
      },
    };
  }
  calcDiscount(deal: DealDetail): number {
    let p1 = deal.products[0].normalPrice;
    let p2 = deal.products[0].dealPrice;
    let discount = p1 - p2;
    let discountP = (discount / p1) * 100;
    return Math.round(discountP);
  }
  truncate(value: string, length: number): string {
    return CommonsUtils.truncate(value, length);
  }

  getThisDeal(dealId: number) {
    if (!this.loginState()) {
      window.scroll(0, 0);
      this.toastr.error(
        'To activate this Deal you must sign in as consumer.',
        'Sign In'
      );
    } else {
      // do navigate to deals view
      this.router.navigateByUrl('/customer/deal-view/' + dealId);
    }
  }

  ratingStars(deal: DealDetail) {
    return CommonsUtils.ratingStars(deal);
  }

  loginState() {
    if (this.appState.get(AppConstants.USERNAME)) {
      return true;
    } else {
      return false;
    }
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Extract the day from the formatted date
    const day: number  = Number(formattedDate.split(' ')[0]);
  
    // Add "th", "st", "nd", or "rd" suffix based on the day
    let daySuffix: string;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }
  
    // Replace the day in the formatted date with the formatted day
    const formattedDay: string = `${day}${daySuffix}`;
    const formattedDateWithDay:string = formattedDate.replace(String(day), formattedDay);
    return formattedDateWithDay;
  };

  ngOnDestroy() {}
}
