import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageThread } from '../core/_models/message.thread';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private manageLoginSignUpButtonClickedSource = new Subject<boolean>();
  manageLoginSignUpButtonClickedSource$ = this.manageLoginSignUpButtonClickedSource.asObservable();

  private manageContactEventClickedSource: Subject<MessageThread> = new Subject();
  private manageMessageRoomRedirect: Subject<MessageThread> = new Subject();


  constructor() { }

  /* Open chat popup on contact seller button */
  notifyContactSellerButtonClick(messageThread: MessageThread) {
    this.manageContactEventClickedSource.next(messageThread);
  }

  getContactSellerButtonClickEvent(){
    return this.manageContactEventClickedSource.asObservable();
  }

  /* Message room redirect */
  notifyMessageRoomRedirection(messageThread: MessageThread) {
    this.manageMessageRoomRedirect.next(messageThread);
  }

  getMessageRoomRedirectionEvent(){
    return this.manageMessageRoomRedirect.asObservable();
  }

  /* Login Signup Button Click */
  notifyLoginSignUpButtonClicked() {
    this.manageLoginSignUpButtonClickedSource.next(false);
  }

  notifyLoginSignInButtonClicked() {
    this.manageLoginSignUpButtonClickedSource.next(true);
  }
}