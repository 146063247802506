import { Component } from '@angular/core';

@Component({
  selector: 'app-popular-stores',
  templateUrl: './popular-stores.component.html',
  styleUrls: ['./popular-stores.component.scss']
})
export class PopularStoresComponent {
  items: { imageUrl: string, linkUrl: string }[] = [
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' },
    { imageUrl: '../../../../assets/images/adidas-img.png', linkUrl: '#' }
  ];
}
