import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class BadWordsService {

    _badWords: any;

    constructor(private http: HttpClient) {
        this.loadBadWords();
    }

    scan(text: string, callback: Function) {
        var word: string, key: string, match: any;
        var regex = /\w+/g

        while (match = regex.exec(text)) {
            word = match[0];
            key = word.toLowerCase();

            if (key in this._badWords && Array.isArray(this._badWords[key])) {
                if (callback(word, match.index, this._badWords[key]) === false) {
                    break;
                }
            }
        }
    }

    profane(text: string):boolean {

        var profane = false;
        this.scan(text, function (word: string, index: number, categories: string[]) {
            profane = true;
            return false; // Stop on first match
        });                      
        return profane;  
    }

    scorecard(text: string) {
        var scorecard = {};

        this.scan(text, function (word: string, index: number, categories: string[]) {
            for (var i = 0; i < categories.length; i += 1) {
                var cat = categories[i];

                if (cat in scorecard) {
                    scorecard[cat] += 1;
                } else {
                    scorecard[cat] = 1;
                }
            };
        });

        return scorecard;
    }

    censor(text: string) {
        var censored = text;

        this.scan(text, function (word: string, index: number, categories: string[]) {
            censored = censored.substr(0, index) +
                word.replace(/\S/g, '*') +
                censored.substr(index + word.length);
        });

        return censored;
    }

    loadBadWords() {        
        this.http
        .get('assets/config/badwords.json')
        .subscribe((resp) => {
            this._badWords = resp;            
        });
    }

}


