import { Injectable } from "@angular/core";
import { DealDetail } from "../_models/deal.detail";

@Injectable()
export class LocalStorageService {

    public getRecentViewedDeals(): DealDetail[] {        
        let json = localStorage.getItem('recent_viewed_deals');
        if (json) {
            return JSON.parse(json) as DealDetail[];
        } else {
            return [];
        }
    }

    public addRecentViewedDeals(deal: DealDetail) {
        let json = localStorage.getItem('recent_viewed_deals');
        let deals = []
        if (json) {
            deals = JSON.parse(json) as DealDetail[];
        }
        if (!deals.find(d => d.dealId == deal.dealId)) {
            if (deals.length == 10) {
                deals.shift(); // at max we want to keep 10 deals in local storage for recently viewed deals
            }
            deals.push(deal);
        }            
        localStorage.setItem('recent_viewed_deals', JSON.stringify(deals));
    }

    public setRecentViewedDeals(deals: DealDetail[]) {
        localStorage.setItem('recent_viewed_deals', JSON.stringify(deals));
    }
}
