import { Injectable } from '@angular/core';
import { DataService } from '../../_services/data.service';
import { AppState } from '../../_store/app.state';
import { ApiConstants } from '../../_utils/apiconstants';
import { RegisterUser } from '../../_models/register.user';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationService {
    private restUrl: string;
    constructor(private dataService: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL);
    }

    registerPartner(registerUser: RegisterUser):Observable<any> {
        let url = this.restUrl + ApiConstants.REGISTER_PARTNER; // register partner
        return this.dataService.create(url, registerUser, null);
    }

    registerCustomer(registerUser: RegisterUser):Observable<any> {
        let url = this.restUrl + ApiConstants.REGISTER; // register customer (User)
        return this.dataService.create(url, registerUser, null);
    }
        
}