<form [formGroup]="registrationForm" autocomplete="off">
  <div class="form-group">
    <label>First Name</label>
    <input
      type="text"
      class="form-control"
      formControlName="firstname"
      value=""
      placeholder="Your first name"
    />
    <mat-error *ngIf="hasError('firstname', 'required')">
      First Name is required
    </mat-error>
    <mat-error *ngIf="hasError('firstname', 'minlength')">
      Min 3 characters required
    </mat-error>
    <mat-error *ngIf="hasError('firstname', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
  </div>

  <div class="form-group">
    <label>Last Name</label>
    <input
      type="text"
      class="form-control"
      formControlName="lastname"
      placeholder="Your last name"
    />
    <mat-error *ngIf="hasError('lastname', 'required')">
      Last Name is required
    </mat-error>
    <mat-error *ngIf="hasError('lastname', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
  </div>
  <div class="form-group">
    <label>Email</label>
    <input
      type="text"
      class="form-control"
      formControlName="email"
      placeholder="Your email"
    />
    <mat-error *ngIf="hasError('email', 'required')">
      Email is required
    </mat-error>
    <mat-error *ngIf="hasError('email', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
    <mat-error *ngIf="hasError('email', 'email')">
      Invalid Email address
    </mat-error>
  </div>
  <div class="form-group">
    <label>Phone</label>
    <input
      type="text"
      class="form-control"
      formControlName="phone"
      value=""
      placeholder="Phone Number"
    />
    <mat-error *ngIf="hasError('phone', 'required')">
      Phone Number is required
    </mat-error>
    <mat-error *ngIf="hasError('phone', 'minlength')">
      Number should be minimum 5 digits
    </mat-error>
    <mat-error *ngIf="hasError('phone', 'maxlength')">
      Number can be maximum 20 digits
    </mat-error>
  </div>
  <div class="form-group">
    <label>Username</label>
    <input
      class="form-control"
      formControlName="username"
      type="text"
      value=""
      placeholder="username"
    />
    <mat-error *ngIf="hasError('username', 'required')">
      Username is required
    </mat-error>
    <mat-error *ngIf="hasError('username', 'minlength')">
      Username should be minimum 3 character
    </mat-error>
    <mat-error *ngIf="hasError('username', 'maxlength')">
      Username can be maximum 50 characters
    </mat-error>
  </div>
  <div class="form-group">
    <label>Password</label>
    <span class="text">
      <input
        class="form-control"
        formControlName="password"
        type="password"
        value=""
        placeholder="Password"
      />
    </span>
    <mat-error *ngIf="hasError('password', 'required')">
      Password is required
    </mat-error>
    <mat-error *ngIf="hasError('password', 'minlength')">
      Password should be minimum 6 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('password', 'maxlength')">
      Password can be maximum 100 character (alpha-numeric)
    </mat-error>
  </div>
  <div class="form-group">
    <label>Confirm Password</label>
    <input
      class="form-control"
      formControlName="confirmPassword"
      type="password"
      value=""
      placeholder="Confirm Password"
    />
    <mat-error *ngIf="hasError('confirmPassword', 'required')">
      Confirm Password is required
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'minlength')">
      Confirm Password should be minimum 6 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">
      Confirm Password can be maximum 100 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'ConfirmPassword')">
      Password does not match
    </mat-error>
  </div>

  <div class="form-group">
    <span class="Checkbox">
      <label>
        <input
          id="toc"
          type="checkbox"
          class="mr-2"
          name=""
          (change)="agreeTermsAndCondition($event)"
          value="" />
          Agree Terms & Conditions
        <a
          routerLink="/customer/terms-conditions"
          class="ml-2"
          target="_blank"
          title="View Terms & Conditions"
          data-dismiss="modal"
        >
          <i class="fa fa-external-link" aria-hidden="true"></i></a
      ></label>
    </span>
  </div>
  <div class="form-group">
    <!-- this captcha site key is used from locoldealseo@gmail.com account, same is there in register-customer.component -->
    <!-- this reference functions captchaSubmitted() are defined in index.html -->
    <div
      class="g-recaptcha"
      data-callback="captchaSubmitted"
      data-expired-callback="captchaExpired"
      data-error-callback="captchaErrored"
      data-sitekey="6Ld0in8kAAAAAJicZKZtI3cvXjD3KupFvIYwtJQX"
    ></div>
  </div>

  <div class="btnWrap">
    <button
      type="submit"
      [disabled]="isButtonDisabled"
      id="submitBtn"
      (click)="submit()"
      class="create-account-disabled submit-btn"
    >
      Create Account
    </button>
  </div>
</form>

