import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
  } from '@angular/core';
  import { Router } from '@angular/router';
  import { DealDetail } from 'src/app/core/_models/deal.detail';
  import { DealsService } from 'src/app/core/_services/deals.service';
  import { OwlOptions } from 'ngx-owl-carousel-o';
  import { AppState } from 'src/app/core/_store/app.state';
  import { AppConstants } from 'src/app/core/_utils/appconstants';
  import { CookieService } from 'ngx-cookie-service';
  import { Title, Meta } from '@angular/platform-browser';
import { CommunicationService } from 'src/app/services/communication.service';
  
@Component({
    selector: 'app-partner-home-page',
    templateUrl: './partner-home-page.html',
    styleUrls: ['./partner-home-page.scss'],
})
export class PartnerHomePageComponent implements OnInit, OnDestroy {
    loginState: boolean = false;

    constructor
    (
        private appState: AppState,
        private communicationService: CommunicationService,
        private router: Router
    ){}

    ngOnInit(): void {
        if (this.appState.get(AppConstants.USERNAME)) {
            this.loginState = true;
        } else {
            this.loginState = false;
        }
    }

    navigatePartnerSignIn(from: boolean): any {
        if(from){
            this.communicationService.notifyLoginSignInButtonClicked();
            this.router.navigateByUrl("/business/signin");
        }
        else{
            this.communicationService.notifyLoginSignUpButtonClicked();
            this.router.navigateByUrl("/business/signup");
        }
    }

    ngOnDestroy() {}
}   
