<section class="deal_category-wrapper">
  <div class="deal_category-inner">
    <div class="container">
      <div class="deal_category-contentWrap">
        <div class="deal_category-head">
          <h2 class="primary-heading text-center">Today's Top <span class="orange-text">Coupons & Offers</span></h2>
        </div>

        <div class="deal_category-content">
          <!-- <ul class="deal_category-list">
            <ng-container *ngFor="let cat of allCategories">
              <li class="deal_category-item" title="{{ cat.title }}" (click)="openDealsForCategory(cat.title)">
                <span class="deal_category-iconWrap">
                  <img [src]="cat.image" alt="{{ cat.title }}" />                  
                </span>
                <span class="deal_category-label">{{ cat.title }}</span>
              </li>
            </ng-container>
          </ul> -->

          <div
            class="nav flex-column nav-pills top_coupons-tablist"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              class="nav-link active"
              id="Most-used-tab"
              data-toggle="pill"
              href="#Most-used"
              role="tab"
              aria-controls="Most-used"
              aria-selected="true"
            >
            <span class="icon-wrapper">
              <img src="assets/images/most-used-img.svg" alt="most-used" class="w-100" />
            </span>
            <span class="iconText">Most used</span>
            </a>
            <a
              class="nav-link"
              id="Travel-tab"
              data-toggle="pill"
              href="#Travel"
              role="tab"
              aria-controls="Travel"
              aria-selected="false"
              >
              <span class="icon-wrapper">
                <img src="assets/images/travel-img.svg" alt="travel" class="w-100" />
              </span>
              <span class="iconText">Travel</span>
            </a>
            <a
              class="nav-link"
              id="Recharge-tab"
              data-toggle="pill"
              href="#Recharge"
              role="tab"
              aria-controls="Recharge"
              aria-selected="false"
              >
              <span class="icon-wrapper">
                <img src="assets/images/recharge-img.svg" alt="recharge" class="w-100" />
              </span>
              <span class="iconText">Recharge</span>
              </a
            >
            <a
              class="nav-link"
              id="Fashion-tab"
              data-toggle="pill"
              href="#Fashion"
              role="tab"
              aria-controls="Fashion"
              aria-selected="false"
              >
              <span class="icon-wrapper">
                <img src="assets/images/fashion-img.svg" alt="fashion" class="w-100" />
              </span>
              <span class="iconText">Fashion</span>
              </a
            >
            <a
              class="nav-link"
              id="Food-tab"
              data-toggle="pill"
              href="#Food"
              role="tab"
              aria-controls="Food"
              aria-selected="false"
              >
              <span class="icon-wrapper">
                <img src="assets/images/food-img.svg" alt="food" class="w-100" />
              </span>
              <span class="iconText">Food</span>
              </a
            >
            <a
              class="nav-link"
              id="Electronics-tab"
              data-toggle="pill"
              href="#Electronics"
              role="tab"
              aria-controls="Electronics"
              aria-selected="false"
              >
              <span class="icon-wrapper">
                <img src="assets/images/electronics-img.svg" alt="electronics" class="w-100" />
              </span>
              <span class="iconText">Electronics</span>
              </a
            >
          </div>
          <div class="tab-content top_coupons-tabcontent" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="Most-used"
              role="tabpanel"
              aria-labelledby="Most-used"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="Travel"
              role="tabpanel"
              aria-labelledby="Travel"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="Recharge"
              role="tabpanel"
              aria-labelledby="Recharge"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="Fashion"
              role="tabpanel"
              aria-labelledby="Fashion"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="Food"
              role="tabpanel"
              aria-labelledby="Food"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div
              class="tab-pane fade"
              id="Electronics"
              role="tabpanel"
              aria-labelledby="Electronics"
            >
            <div class="offer_boxWrap">
              <div class="offer_box" *ngFor="let item of items">
                <div class="offer_box-inner">
                  <div class="offer_box-head">
                    <span class="left">Local Deal <strong>Exclusive</strong></span>
                    <span class="right"><img [src]="item.imgUrl" alt="" /></span>
                  </div>
                  <div class="offer_box-body">
                    <p>{{ item.desc }}</p>
                  </div>
                  <div class="offer_box-foot">
                    <a [href]="item.linkUrl">See All Deals</a>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
