import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { Observable } from 'rxjs';
import { Deal } from '../_models/deal';
import { AppConstants } from '../_utils/appconstants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Keyword } from '../_models/keyword';

@Injectable()
export class DealService {

    restUrl: string;
    constructor(private dataService: DataService, private appState: AppState, private http: HttpClient) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL);
    }

    // ----- DEAL -----
    getDealCategories():Observable<any> {
        let url = this.restUrl + ApiConstants.CATEGORY;
        return this.dataService.get(url);
    }

    getDealCategoryNames():Observable<any> {
        let url = this.restUrl + ApiConstants.CATEGORY_NAMES;
        return this.dataService.get(url);
    }

    getDealSubcategories(categoryId: string):Observable<any> {
        let url = this.restUrl + ApiConstants.SUBCATEGORY + '/' + categoryId;
        return this.dataService.get(url);
    }

    getDealsByCategory(categoryId: any): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.BY_CATEGORY + '/' + categoryId;
        return this.dataService.get(url);
    }

    getDealSubcategoriesByCatName(categoryName: string): Observable<any> {
        let url = this.restUrl + ApiConstants.SUBCAT_BY_CAT + '?categoryName=' + categoryName;
        return this.dataService.get(url);
    }

    createDeal(deal: Deal):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL;
        return this.dataService.create(url, deal, null);
    }

    updateDeal(deal: Deal):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL;
        return this.dataService.update(url, deal);
    }

    loadDeal(id: string):Observable<Deal> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.BY_ID + '/' + id;
        return this.dataService.get(url);
    }

    listDeals():Observable<any> {     // gets all Deals
        let url = this.restUrl + ApiConstants.DEAL;
        return this.dataService.get(url);
    }

    listDealsByOwner(dealOwner: string, dealStatus: string):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.BY_OWNER + '/' 
            + dealOwner + '?status=' + dealStatus;
        return this.dataService.get(url);
    }

    publishDeal(dealId: number): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.PUBLISH + '/' + dealId;
        return this.dataService.update(url, null);
    }

    unpublishDeal(dealId: number): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.UNPUBLISH + '/' + dealId;
        return this.dataService.update(url, null);
    }

    activateDeal(dealId: number): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.ACTIVATE + '/' + dealId;
        return this.dataService.update(url, null);
    }

    deactivateDeal(dealId: number): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.DEACTIVATE + '/' + dealId;
        return this.dataService.update(url, null);
    }

    deleteDeal(id:number):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + '/' + id;
        return this.dataService.delete(url);
    }

    searchKeywords(text: string): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.SEARCH_KEYWORDS
            + '?text=' + text;        
        return this.dataService.get(url);
    }

    searchDealByKeyword(keyword: Keyword): Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.BY_KEYWORD
            + '?id=' + keyword.id;
        return this.dataService.get(url);
    }

    // ----- PRODUCTS ---------

    createProducts(products:any[]):Observable<any> {
        let url = this.restUrl + ApiConstants.PRODUCT;
        return this.dataService.create(url, products, null);
    }

    deleteProduct(id: string): Observable<any> {
        let url = this.restUrl + ApiConstants.PRODUCT + '/' + id;
        return this.dataService.delete(url);
    }

    // ------ STORE LOCATION -------

    deleteLocation(id: string): Observable<any> {
        let url = this.restUrl + ApiConstants.STORE_LOCATION + '/' + id;
        return this.dataService.delete(url);
    }

    getSellerLocations(username: string): Observable<any> {
        let url = this.restUrl + ApiConstants.STORE_LOCATION_SELLER + '?username=' + username;
        return this.dataService.get(url);
    }

    createLocationsForSeller(request: any): Observable<any> {
        let url = this.restUrl + ApiConstants.STORE_LOCATION_SELLER;
        return this.dataService.create(url, request, null);
    }

    createLocations(locations: any[]): Observable<any> {
        let url = this.restUrl + ApiConstants.STORE_LOCATION;
        return this.dataService.create(url, locations, null);
    }

    saveBusinessHours(businessHours: any): Observable<any> {
        let url = this.restUrl + ApiConstants.STORE_BZ_HOURS;
        return this.dataService.update(url, businessHours);
    }

    // -------- IMAGES ---------

    uploadPhoto(body:any):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL_PHOTO_UPLOAD;
        return this.dataService.create(url, body, null);
    }

    agreeOnDeal(id: string):Observable<any> {
        let url = this.restUrl + ApiConstants.DEAL + ApiConstants.AGREEMENT + '/' + id;
        return this.dataService.update(url, null);
    }
    
    public listUsStates(): Observable<any> {
        let url = this.restUrl + ApiConstants.STATES_OF_US;
        return this.dataService.get(url);
    }

    public updateProfileBrand(
        formData: any,
        body: any
      ): Observable<any> {
        let url = this.restUrl + ApiConstants.UPDATE_PHOTO_BRAND;
        const reqHeader: Object = {
          responseType: 'json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        };        
        let headers = new HttpHeaders();
        headers.set('Access-Control-Allow-Origin', '*');
        formData.append('jsondata', JSON.stringify(body));
        return this.http.post(url, formData, reqHeader);
      }

    public uploadSellerProfilePhoto(
        formData: any,
        body: any
      ): Observable<any> {
        let url = this.restUrl + ApiConstants.UPDATE_PHOTO_SELLER_PROFILE;
        const reqHeader: Object = {
          responseType: 'json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        };        
        let headers = new HttpHeaders();
        headers.set('Access-Control-Allow-Origin', '*');
        formData.append('jsondata', JSON.stringify(body));
        return this.http.post(url, formData, reqHeader);
    } 

    public getSellerByBusinessName(businessName: string): Observable<any> {
        let url = this.restUrl + ApiConstants.SELLER_BY_BRAND + '?brand=' + businessName;
        return this.http.get(url);
    }

    public getAllCountries(): Observable<any> {
        let url = this.restUrl + ApiConstants.COUNTRIES;
        return this.dataService.get(url);
    }

    public getAllAvailableStores(page: number, size:number): Observable<any> {
        let url = this.restUrl + `${ApiConstants.ALL_STORES}/${size}/${page}`

        return this.dataService.get(url);
    }

    public getExclusiveStores(): Observable<any> {
        let url = this.restUrl + ApiConstants.EXCLUSIVE_STORES;

        return this.dataService.get(url);
    }

    public getAllStatesByCountryName(country: string): Observable<any> {
        let url = this.restUrl + `/getAllStatesByCountryName?countryName=${country}`;
        return this.dataService.get(url);
    }
    public getAllStatesByCountryNames(countries: string[]): Observable<any> {
        const url = `${this.restUrl}/getAllStatesByCountries`;
        const params = new HttpParams().set('countryName', countries.join(','));  
        return this.dataService.getWithParams(url, params);
      }

}