import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { DataService } from './data.service';

@Injectable()
export class CartService {

    restUrl: string;

    constructor(private dataService: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL) + ApiConstants.CART;    
    }

    addProductToCart(request: any): Observable<any> {
        let url = this.restUrl;
        return this.dataService.create(url, request, null);
    }

    checkDealAddToCart(dealId: string, username: string): Observable<any> {
        let url = this.restUrl + ApiConstants.CHECK_ADD_TOCART
            + '?dealId=' + dealId + '&customerUsername=' + username;
        return this.dataService.get(url);
    }

    getCartItems(username: string): Observable<any> {
        let url = this.restUrl + '/' + username;
        return this.dataService.get(url);
    }

    removeItemFromCart(username: string, cartId: number, productId: number) {
        let url = this.restUrl + '/' + username + '/' + cartId + '/' + productId;
        return this.dataService.delete(url);
    }

    updateProductQuantity(request: any): Observable<any> {
        let url = this.restUrl  + '/quantity';
        return this.dataService.update(url, request);
    }

    saveDealForLaterOrder(dealId: number, username: string): Observable<any> {
        let url = this.restUrl + '/saved-deals' + '?dealId=' + dealId + '&customerUsername=' + username;
        return this.dataService.create(url, null, null);
    }

    deleteDealFromSaved(dealId: number, username: string): Observable<any> {
        let url = this.restUrl + '/saved-deals' + '?dealId=' + dealId + '&customerUsername=' + username;
        return this.dataService.delete(url);
    }

    getSavedDealsForLater(username: string): Observable<any> {
        let url = this.restUrl + '/saved-deals' + '?customerUsername=' + username;
        return this.dataService.get(url);
    }
}