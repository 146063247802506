<header class="header_main-wrapper">
  <!-- First Navbar -->
  <div class="header_main-wrapper-inner">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container navbar-container">
        <div class="navbar-logoWrap d-flex align-items-center justify-content-center">
          <a class="navbar-brand" href="/" title="Locoldeal">
            <img class="logo" src="assets/images/logos/locoldeal-logo.png" alt="Logo" />
          </a>
          <div class="txt-business">
            Business
          </div>
        </div>
        
        <div class="navbar-btnsWrap">
          <!-- <ng-container *ngIf="screenWidth <= 991">
              <div class="dropdown search-toggleWrap">
                <button class="dropdown-toggle search-toggle" type="button" id="dropSearchButton" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-search"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropSearchButton">
                  <app-header-search></app-header-search>
                </div>
              </div>
            </ng-container> -->
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"
            aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <div class="close-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x-lg"
                viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
          </button>
        </div>
        
        <div class="header-btnsWrap" style="display: flex; gap: 15px" *ngIf="!loginPage">
          <span class="business-a-btnwrap" href="">
            Already Have Business Account ?
          </span>
          <button class="btn custom-btn-white light" type="button" data-toggle="modal" data-target="#modalLRForm"
            title="Login" (click)="navigatePartnerSignIn(true)">
            Log in
          </button>
        </div>

        <div class="header-btnsWrap" style="display: flex; gap: 15px" *ngIf="loginPage">
          <span class="business-a-btnwrap">
            Don't Have Business Account ?
          </span>
          <button class="btn custom-btn-white light" type="button" data-toggle="modal" data-target=""
            title="Register" (click)="navigatePartnerSignIn(false)">
            Register
          </button>
        </div>
      </div>
    </nav>
  </div>
</header>