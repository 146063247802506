import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor(
    private appState: AppState,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  createDealCheck() {
    if (this.appState.get(AppConstants.USERNAME)) {
      let userRole = this.appState.get(AppConstants.USER_ROLE);
      if (userRole && userRole.roleName == 'SITE_PARTNER') {
        this.router.navigateByUrl('/partner/deals/new');
      } else {
        this.toastr.info(
          'Please login as Partner to create a deal',
          'Partner ?'
        );
      }
    } else {
      this.toastr.info(
        'Please login OR signup as Partner to create a deal',
        'Partner ?'
      );
    }
  }

  comingSoon() {
    this.toastr.info('This feature is coming soon...',);
  }
}
