import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { AppState } from '../core/_store/app.state';
import { ApiConstants } from '../core/_utils/apiconstants';
import { AppConstants } from '../core/_utils/appconstants';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ChatMessageService } from '../core/_services/chat.message.service';
import { isPlatformBrowser } from '@angular/common';
import { Socket, io } from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private userName: string;
  private restUrl = this.appState.get(ApiConstants.REST_URL);
  private header: any;
  private socket: Socket;
  messages: any;
  messagedChanges$: Subject<any> = new Subject();
  notificationDotChanges$: BehaviorSubject<number> = new BehaviorSubject(0);


  messageNotificationCollector: any = {};

  constructor(
    private appState: AppState,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private chatService: ChatMessageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.initializeWebSocket();
    this.subscribeToListenMessage();
  }

  subscribeToListenMessage() {
    this.userName = this.cookieService.get(AppConstants.USERNAME);

    this.increaseInitialNotificationMessageCount();
    this.header = this.userName && {
      Authorization: this.userName && this.cookieService.get(AppConstants.COOKIE_TOKEN)
    }
    this.userName && this.getMessage(this.userName).subscribe((message) => {
        this.messages = message;
        if (this.userName !== this.messages.senderName) {
          if (this.messageNotificationCollector[this.messages.messageId]) {
            this.messageNotificationCollector[this.messages.messageId].push(this.messages);
          } else {
            this.messageNotificationCollector[this.messages.messageId] = [this.messages];
            this.notificationDotChanges$.next(Object.keys(this.messageNotificationCollector).length);
          };
        };

      this.messagedChanges$.next(this.messages);
    });
  }

  initializeWebSocket(): void {
    this.userName  = this.cookieService.get(AppConstants.USERNAME);
      if (!this.userName) {
        return;
    }
    this.changeSocketConfig(this.userName);
  }

  sendMessage(messageDTO: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        this.socket.emit("messageFromClient", messageDTO, this.header);
        
        resolve(true); // Resolve with true for success
      } catch (error) {
        reject(error); // Reject with the error for failure
      }
    });
  }

  sendAttachment(messageDTO: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        this.socket.emit("messageFromClientAttachment", messageDTO, this.header);
        resolve(true); // Resolve with true for success
      } catch (error) {
        reject(error); // Reject with the error for failure
      }
    });
  }

  getMessage(userName: string): Observable<any> {
    return new Observable(observer => {
      const handler = (message: any) => {
        observer.next(message)
      };
      this.socket.on("newMessage", handler);

      // Return teardown logic in case Observable is unsubscribed
      return () => this.socket.removeListener("newMessage", handler);
    });
  }

  updateNotificationCount(): void {
      const count = Object.keys(this.messageNotificationCollector).length;
      this.notificationDotChanges$.next(count);
  }

  deleteReadNotificationMessage(messageId:number):void {
    delete this.messageNotificationCollector[messageId];
  }

  increaseInitialNotificationMessageCount() {
    let isPartner = this.userName && this.cookieService.get(AppConstants.USER_ROLE) == 'SITE_PARTNER';
    this.userName && this.chatService.getNotificationsCount(this.userName, isPartner).subscribe((data: []) => {
      data && data.forEach((it: any) => {
        this.messageNotificationCollector[it] = [];
      })
      this.notificationDotChanges$.next(Object.keys(this.messageNotificationCollector).length);
    });
  }

  changeSocketConfig(username:string) {
    this.socket?.disconnect();
    let token = this.userName && this.cookieService.get(AppConstants.COOKIE_TOKEN);
    if(isPlatformBrowser(this.platformId) && username) { // to check if this is runing in browser
      this.socket = io(AppConstants.WEB_SOCKET_URL, {
        query: {
          userId: username
        },
        auth: {
          token: token
        }
      });
    }
  }
}
