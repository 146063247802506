
<div class="chat-container">
  <ul class="list-group">
    <li class="list-group-item" style="background:#ff6d42;">
        <div class="row">
            <div class="col">
                <div class="mainheading">
                    <div class="icon" *ngIf="currentMessageThread">
                      <a (click)="deSelectedMessageThread()" class="text-white">
                        <i class="mt-2 fa fa-chevron-left"></i>
                      </a>
                    </div>
                    <div class="icon" *ngIf="!currentMessageThread">
                        <a (click)="chatToggle()" class="text-white">
                            <i class="mt-2 fa fa-times"></i>
                          </a>
                      </div>
                    <div class="input" *ngIf="!currentMessageThread">
                        <input type="text" name="" placeholder="Search..." id="" class="form-control">
                    </div>
                    <div class="input" *ngIf="currentMessageThread" title="Click to View Deal">                           
                        <a (click)="openDeal(currentMessageThread)">
                            {{currentMessageThread.dealName}} ({{currentMessageThread.consumerName}})
                        </a>
                      </div>
                    <div class="image pl-2" title="Consumer">
                        <i class="fa fa-user user-avtar-icon-small"></i>
                    </div>
                </div>
            </div>
        </div>
    </li>
    <li class="list-group-item p-0 chat-body" >
         <div class="chats" *ngIf="!currentMessageThread">
             <ul class="row">
                 <li class="heading col-12">Direct Messages</li>
                 <li class="message col-12"> 
                    <div class="mesageBox" *ngFor="let msgThread of messageThreads;" (click)="selectedMessageThread(msgThread)">
                        <div class="image">
                            <span style="padding: 10px 15px;" class="ratings-author-initial">{{nameInitial(msgThread.dealName)}}</span>                              
                        </div>
                        <div class="text">
                            <h6>{{msgThread.dealName}} ({{msgThread.consumerName}})</h6>
                            <p class="{{msgThread.messageProviderRead ? '' : 'message-unread-state'}}">{{msgThread.subject}}</p>
                        </div> 
                        <div class="unread-dot" *ngIf="unreadMessages[msgThread.messageId]"></div>
                    </div>                      
                </li>                
             </ul>
         </div>

         <div class="chats chat-message-thread" *ngIf="currentMessageThread">
            <ul class="row chat-messages-scrollable" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                <li class="message col-12">
                    <div style="width: 100%;">
                        <div class="chat-message " *ngFor="let message of currentThreadMessages;">
                            <span class="chat-message-text {{message.consumerName == message.senderName ? 'to-me' : 'from-me'}}">
                                <span>{{message.message}} </span>
                                <span *ngIf="message.attachmentPath">
                                    <a target="_blank" [href]="message.attachmentPath">File</a>
                                </span>
                                <br/>
                                <span class="chat-message-time">
                                  {{messageSentTime(message)}}
                                </span>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="row chat-message-sender">
                <li class="" >
                  <form [formGroup]="sendMessageForm" novalidate (ngSubmit)="sendMessage()">
                    <input type="text" formControlName="sendMessageText" placeholder="type message here" class="form-control chat-message-input-box" #msgInputBox>
                    <input  type="file" hidden id="attachment" accept="image/*" (change)="attachmentSelected($event)" />
                    <button class="ml-1" (click)="sendAttachmentInMessage()" type="button" style="width:9%; border-radius: 50%; background-color:#ff6d41;"><i class="fa fa-paperclip"></i></button>
                    <button class="ml-1" type="submit" style="width:10%"><i class="fa fa-send"></i></button>
                  </form>
                </li>
            </ul>
         </div>

     </li>

  </ul>
</div>
