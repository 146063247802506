<div class="cl partner-header-wrap">
  <div class="partner-header-inner left">
    <button type="button" class="menuToggle" (click)="toggleMenu()" title="Toggle Menu">
      <i class="fa fa-bars"></i>
    </button>
  </div>
  <div class="partner-header-inner right">
    <ul class="actions-list">
      <li class="actions-item">
        <a class="header-action" (click)="toggleChatWindow()" title="Chat">
          <span class="icon" [ngClass]="{'shift-right': notificationCount}"><i class="fa fa-comments"></i>
            <span class="notification-dot" *ngIf="notificationCount">{{notificationCount}}</span>
          </span>
          Chat
        </a>
      </li>
      <li class="actions-item">
        <a class="header-action" title="Logout" (click)="logout()"
          ><span class="icon"><i class="icofont-sign-out"></i></span>Logout</a
        >
      </li>
    </ul>
  </div>
</div>

<!-- Prtner Chat Window -->
<ng-container *ngIf="partnerChatToggle">
  <app-partner-chat (close)="onPartnerChatClose($event)"></app-partner-chat>
</ng-container>
<!-- Prtner Chat Window -->
