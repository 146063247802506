import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppState } from '../_store/app.state';
import { AppConstants } from '../_utils/appconstants';

@Injectable()
export class AuthAccountService {

    constructor(private cookieService: CookieService, private appState: AppState) {}

    public clearUserSession() {
        this.cookieService.deleteAll("/");
        this.appState.set(AppConstants.COOKIE_TOKEN, null);
        this.appState.set(AppConstants.VERIFY_USERNAME, '');
        this.appState.set(AppConstants.DEAL_EDIT, '');
        this.appState.set(AppConstants.USERNAME, '');
        this.appState.set(AppConstants.USER_ID, '');
        this.appState.set(AppConstants.USER_ROLE, '');
        this.appState.set(AppConstants.USER_FULLNAME, '');
    }

    public isLoggedIn(): boolean {
        return this.appState.get(AppConstants.COOKIE_TOKEN) != null;
    }
}