import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAccountService } from 'src/app/core/_services/auth.account.service';
import { LayoutService } from '../../common/layout.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-partner-header',
  templateUrl: './partner-header.component.html',
  styleUrls: ['./partner-header.component.scss'],
})
export class PartnerHeaderComponent implements OnInit {
  partnerChatToggle: boolean = false;
  public notificationCount: number;
  @Output('onUserLogin') onUserLoginEvent = new EventEmitter<string>(); // emits user role

  constructor(
    private authAccountService: AuthAccountService,
    private layoutService: LayoutService,
    private router: Router,
    private ws: SocketService
  ) {}

  ngOnInit(): void {
    this.ws.notificationDotChanges$.subscribe((notification: number) => {
      this.notificationCount = notification;
    })
  }

  logout() {
    // this.authAccountService.clearUserSession();
    // window.location.href = '/';
    // this.router.navigateByUrl("/");

    Swal.fire({
      title: 'Logout as Seller?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#ff6d41',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.authAccountService.clearUserSession();
        // window.location.href = '/';
        this.authAccountService.clearUserSession();
        window.location.href = '/';
        // this.router.navigateByUrl("/");
      }
    });
  }

  toggleMenu() {
    this.layoutService.toggleMenu();
  }

  toggleChatWindow() {
    this.partnerChatToggle = !this.partnerChatToggle;
  }

  onPartnerChatClose($event) {
    this.partnerChatToggle = false;
  }

  navigateToCoupons() {
    this.router.navigateByUrl('/partner');
  }
}
