<div class="modal zoom-in show" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" #searchModal>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <button type="button" id="closeSearchModal" class="btn-close" data-dismiss="modal" #closeModal
        aria-label="Close"></button>

      <div class="modal-body search-panel">
        <div class="search-panel-title">
          <h1>Explore a treasure trove of exclusive</h1>
          <h1 class="text-orange">offers tailored just for you</h1>
        </div>
        <div class="search-panel-wrapper">
          <input class="search-panel-input" type="text" id="searchPanelOnLaunch" [(ngModel)]="searchText"
            placeholder="Search For your Favourite Business, Deals & Coupons..." #searchPanelOnLaunch
            (keyup.enter)="doSearch()" />
          {{searchPanelOnLaunch.focus()}}
        </div>
        <ul class="search-panel-list">
          <ng-container *ngFor="let item of trendinList">
            <li class="search-panel-item">
              <a class="search-panel-item-link" href="#" title="{{item}}">{{item}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>