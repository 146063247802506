import { Component, OnInit, OnDestroy } from '@angular/core';
import { DealDetail } from 'src/app/core/_models/deal.detail';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/core/_services/coupon.service';
import { Coupon } from 'src/app/core/_models/coupon';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';


@Component({
  selector: 'app-deals-collection',
  templateUrl: './deals-collection.component.html',
  styleUrls: ['./deals-collection.component.scss']
})
export class DealsCollectionComponent {
  coupons: any[];
  dealCaraouselOptions: OwlOptions;
  MINIMUM_COUPONS: number;

  constructor(private appState: AppState,
    private toastr: ToastrService, private router: Router, private couponService: CouponService) {
      this.coupons = [];
      this.MINIMUM_COUPONS = 4;
    }

  ngOnInit(): void {    
    this.setAllCaraouselOptions();
    this.getAllCoupons();
  }

  getAllCoupons() {
    this.couponService.getCouponOffers(0, 10, '', '', '', 0, '').subscribe(resp => {
      this.coupons = resp.coupons;
      const missing = this.MINIMUM_COUPONS - this.coupons.length;
      for (var i=0; i < missing; i++) {
        this.coupons.push(this.dummyCoupon());
      }
    });
  }

  setAllCaraouselOptions() {
    this.dealCaraouselOptions = {
      loop: true,
      dots: false,
      margin: 16,
      nav: true,
      navSpeed: 700,
      navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1000: {
          items: 4,
        },
        1400: {
          items: 5,
        },
      },
    };
  }
  calcDiscount(deal: DealDetail): number {
    let p1 = deal.products[0].normalPrice;
    let p2 = deal.products[0].dealPrice;
    let discount = p1 - p2;
    return discount;
  }
  truncate(value: string, length: number): string {
    return CommonsUtils.truncate(value, length);
  }
  
  getThisCoupon() {
    if (!this.loginState()) {
      window.scroll(0,0);
      this.toastr.error('To activate this Coupon you must sign in as consumer.', 'Sign In');
    } else {
      // do navigate to coupons browse
      this.router.navigateByUrl('/customer/coupons-browse');
    }
  }
  loginState() {
    if (this.appState.get(AppConstants.USERNAME)) {
      return true;
    } else {
      return false;
    }
  }

  dummyCoupon() {
    return {
      brandLogo: 'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png',
      brandName: 'Brand Name',
      category: 'Food',
      couponCode: 'CPN1000',
      discount: '',
      effectiveFrom: "2023-01-01T17:36:41",
      validUpTo: "2023-10-01T17:36:41",
      enabled: true,
      isFlatDiscount: false,
      longDescription: "flat discount on product",
      partnerId: 1,
      shortTitle:"Flat 2$ off"
    };
  }
  ngOnDestroy() {}
}
