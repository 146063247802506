import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppState } from "../_store/app.state";
import { ApiConstants } from "../_utils/apiconstants";
import { DataService } from "./data.service";

@Injectable()
export class RatingService {
    
    restUrl: string;

    constructor(private dataService: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL) + ApiConstants.RATING;
    }

    public submitDealReview(rating: any): Observable<any> {
        return this.dataService.create(this.restUrl, [rating], null);
    }

    public updateDealReview(rating: any): Observable<any> {
        return this.dataService.update(this.restUrl, rating);
    }

    public getRatingByAuthor(dealId: number, author: string): Observable<any> {
        let url = this.restUrl + '/' + dealId + '/' + author;
        return this.dataService.get(url);
    }

    public getRagingsForDeal(dealId: number): Observable<any> {
        let url = this.restUrl + '/list/' + dealId;
        return this.dataService.get(url);
    }

}
