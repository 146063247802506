<div class="progress-loader" [hidden]="!loading">
  <!-- <div class="loading-spinner">
    <img src="assets/images/timer.png" />
    <span class="loading-message">Please wait...</span>
  </div> -->
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div> -->
