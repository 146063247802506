<!-- <app-header *ngIf="isCustomer" (onUserLogin)="onUserLogin($event)"></app-header> -->
<!-- <app-partner-header *ngIf="!isCustomer" (onUserLogin)="onUserLogin($event)"></app-partner-header> -->

<!-- Partner and customer -->
<router-outlet></router-outlet>
<!-- Partner and customer -->

<app-http-loader></app-http-loader>

<app-footer></app-footer>
