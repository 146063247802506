import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChatMessageService } from 'src/app/core/_services/chat.message.service';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';
import { Message } from '../../../core/_models/message';
import { MessageThread } from '../../../core/_models/message.thread';
import { ScrollToBottomDirective } from '../../common/scroll-to-bottom.directive';
import {PartnerHeaderComponent} from 'src/app/views/theme/partner-header/partner-header.component'
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-partner-chat',
  templateUrl: './partner-chat.component.html',
  styleUrls: ['./partner-chat.component.css'],
})
export class PartnerChatComponent implements OnInit {
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  @ViewChild("msgInputBox") msgInputBox:ElementRef;

  @Output('close') closeEvent = new EventEmitter<boolean>();

  partnerUsername: string;

  sendMessageForm: FormGroup;

  messageThreads: MessageThread[];
  currentMessageThread: MessageThread;

  currentThreadMessages: Message[];
  unreadMessages: { };
  messages: any;

  constructor(
    private appState: AppState,
    private formBuilder: FormBuilder,
    private chatMessageService: ChatMessageService,
    private toastr: ToastrService,
    private router: Router,
    private partnerHeaderComponent: PartnerHeaderComponent,
    private ws: SocketService
  ) {
    this.messageThreads = [];
    this.currentThreadMessages = [];
  }

  ngOnInit(): void {
    this.partnerUsername = this.appState.get(AppConstants.USERNAME);
    this.createSendMessageForm();
    this.loadMessagThreads();
    this.ws.messagedChanges$.subscribe((message: any) => {
      if (this.currentMessageThread?.messageId == message.messageId) {
        this.currentThreadMessages.push(message);
        this.ws.deleteReadNotificationMessage(this.currentMessageThread.messageId);
        this.ws.updateNotificationCount();
      }
      if (message.senderName === this.partnerUsername) {
        this.toastr.success('Message sent.', 'SUCCESS');
        if (this.sendMessageForm.disabled) {
          this.sendMessageForm.enable();
          this.sendMessageForm.reset();
          this.msgInputBox && this.msgInputBox.nativeElement.focus();
        }
      }
    })
    this.unreadMessages = this.ws.messageNotificationCollector;
  }

  createSendMessageForm() {
    this.sendMessageForm = this.formBuilder.group({
      sendMessageText: new FormControl('', Validators.required),
    });
  }

  loadMessagThreads() {
    this.chatMessageService
      .getMessageThreadsProvider(this.partnerUsername)
      .subscribe((resp) => {
        this.messageThreads = resp as MessageThread[];
      });
  }

  loadMessages(messageId: number) {
    this.currentThreadMessages = [];
    this.ws.deleteReadNotificationMessage(messageId);
    this.ws.updateNotificationCount();
    this.unreadMessages = this.ws.messageNotificationCollector;
    this.chatMessageService
      .getMessagesForThread(messageId)
      .subscribe((resp) => {
        this.currentThreadMessages = resp.msgThreads as Message[];
      });
  }

  selectedMessageThread(msgThread_: MessageThread) {
    this.currentMessageThread = msgThread_;
    msgThread_.messageProviderRead = true;
    this.chatMessageService
      .markMessageReadProvider(this.currentMessageThread.messageId + '')
      .subscribe((resp) => {
        console.log('message opened');
      });

    this.loadMessages(this.currentMessageThread.messageId);
  }

  deSelectedMessageThread() {
    this.currentMessageThread = undefined;
    this.currentThreadMessages = [];
  }

  sendMessage() {
    if (this.sendMessageForm.invalid) {
      console.log('form invalid');
      return;
    }
    let messageObj = new Message();
    messageObj.messageId = this.currentMessageThread.messageId;
    messageObj.consumerName = this.currentMessageThread.consumerName;
    messageObj.providerName = this.partnerUsername;
    messageObj.senderName = this.partnerUsername;
    messageObj.message = this.sendMessageForm.controls.sendMessageText.value;
    this.ws.sendMessage(messageObj)
      .then(() => {
        this.sendMessageForm.disable();
        setTimeout(() => {
          if (this.sendMessageForm.disabled) {
            this.sendMessageForm.enable();
            this.msgInputBox.nativeElement.focus();
          }
        }, 7000);
        })
      .catch((error) => {
        this.toastr.error('Failed to send message.', 'ERROR');
        console.error('Error sending message:', error);
      });
  }

  sendAttachmentInMessage() {
    let element = document.querySelector('#attachment') as HTMLElement;
    element.click();
  }

  chatToggle(){
    this.partnerHeaderComponent.partnerChatToggle=false;
  }

  attachmentSelected($event: any) {
    let messageObj = new Message();
    messageObj.messageId = this.currentMessageThread.messageId;
    messageObj.consumerName = this.currentMessageThread.consumerName;
    messageObj.providerName = this.partnerUsername;
    messageObj.senderName = this.partnerUsername;
    messageObj.message = 'Sent an Attachment';

    const formData = new FormData();
    formData.append('jsondata', JSON.stringify(messageObj));
    formData.append('file', $event.target.files[0]);

    this.chatMessageService.sendMessageAttachment(formData).subscribe(
      (resp) => {
        this.toastr.success('Message sent.', 'SUCCESS');
        this.loadMessages(this.currentMessageThread.messageId);
        this.sendMessageForm.reset();
        this.ws.sendMessage(resp);
      },
      (err) => {
        this.toastr.error('Failed to send Message.', 'ERROR');
      }
    );
  }

  openDeal(messageThread: MessageThread) {
    let url = '/customer/deal-view/' + messageThread.dealId;
    setTimeout(() => {
      this.closeEvent.emit(true);
    }, 100);
    this.router.navigateByUrl(url);
  }

  messageSentTime(message: Message) {
    if (!message.created_DT) return '';
    let date: Date = new Date(message.created_DT);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  // ---  UTILS  --- //

  nameInitial(name: string) {
    return CommonsUtils.nameInitial(name);
  }
}
