import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DealsService } from 'src/app/core/_services/deals.service';

@Component({
  selector: 'app-deal-categories',
  templateUrl: './deal-categories.component.html',
  styleUrls: ['./deal-categories.component.scss'],
})
export class DealCategoriesComponent implements OnInit {
  allCategories: any[] = [];
  constructor(private dealsService: DealsService, private router: Router) {}

  ngOnInit(): void {
    this.allCategories = this.dealsService.getAllCoupons();
  }

  openDealsForCategory(category: string) {
    this.router.navigate(['/customer/product-search', { cat: category }]);
  }
  items: { imgUrl: string, desc: string, linkUrl: string }[] = [
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' },
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' },
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' },
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' },
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' },
    { imgUrl: '../../../../assets/images/dell-img.png', desc: 'Exclusive Offer : Up To 50% OFF + Flat   10% OFF + Extra 10% OFF On Prepaid Orders', linkUrl: '#' }
  ];
}
