import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../core/_auth/services/auth.service';
import { AppConstants } from '../../../core/_utils/appconstants';
import { TokenResponse } from 'src/app/core/_models/token.response';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/core/_store/app.state';
import jwt_decode from 'jwt-decode';
import { ApiConstants } from 'src/app/core/_utils/apiconstants';
import { SocketService } from 'src/app/services/socket.service';

declare var FB: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @Output('onLoginSuccess') loginSuccessEvent = new EventEmitter<any>();
  @Output('onForgotPwdClick') onForgotPwdClickEvent = new EventEmitter<any>();
  @Output('signUpButtonEvent') signUpButtonEvent = new EventEmitter<boolean>(); // emits true-> singup, false->signin

  loginForm: FormGroup;
  openVerifyEmail: Function;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private appState: AppState,
    private ws: SocketService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngAfterViewInit(): void {
    // nothing
  }

  ngOnInit(): void {
    let accessToken = this.cookieService.get(AppConstants.COOKIE_TOKEN);
    if (accessToken) {
      // const username = this.appState.get(AppConstants.USERNAME);
      // this.loginSuccessEvent.emit(username);
      // this.router.navigateByUrl('/');
      return;
    }

    this.initLoginForm();
    // console.log('login component. init DONE');

    window.addEventListener('userAuthGoogleSignin', (event: any) => {
      let googleUserCred = jwt_decode(event.googletoken);
      this.loginUserWithGoogleToken(googleUserCred, event.googletoken);
    });

    window.addEventListener('userAuthFacebookSignin', (event: any) => {
      this.loginUserWithFacebookToken(event.fbToken);
    });
  }

  // setter to be called from home-page (or some parent component)
  setOpenVerifyEmail(openVerifyEmail: Function) {
    this.openVerifyEmail = openVerifyEmail;
  }

  submit() {
    const controls = this.loginForm.controls;
    const authData = {
      username: controls.username.value,
      password: controls.password.value,
    };

    this.auth.login(authData.username, authData.password).subscribe(
      (resp) => {
        let tokenResp: TokenResponse = resp;
        if(resp.userRole == "SITE_USER"){
          this.loginSuccess(tokenResp, authData.username);
        }
        else{
          this.toastr.warning("Please Enter the Credentials for Customer", 'WARNING');
        }
        
      },
      (err) => {
        console.log('login ERROR', err);
        if (err.status == 401) {
          this.toastr.error('Invalid credentials', 'ERROR');
        } else if (err.status == 500) {
          this.toastr.error(err.error.message, 'ERROR');
          if (
            err.error.message == 'User is not verified' &&
            this.openVerifyEmail
          ) {
            this.appState.set(AppConstants.VERIFY_EMAIL, '');
            this.appState.set(AppConstants.VERIFY_USERNAME, authData.username);
            this.openVerifyEmail();
          }
        }
      }
    );
    // this.cookieService.set(AppConstants.COOKIE_TOKEN, authData.username, AppConstants.TOKEN_VALIDITY, "/");
    // this.cookieService.set(AppConstants.USERNAME, authData.username, AppConstants.TOKEN_VALIDITY, "/");
    // this.router.navigateByUrl("/");
  }

  loginSuccess(tokenResp: TokenResponse, username: string) {
    // TODO: define common success login code here
    this.cookieService.set(
      AppConstants.COOKIE_TOKEN,
      tokenResp.token,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USERNAME,
      username,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USER_ID,
      tokenResp.userId,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USER_ROLE,
      tokenResp.userRole,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.appState.set(AppConstants.COOKIE_TOKEN, tokenResp.token);

    this.loginSuccessEvent.emit(username);
    this.ws.initializeWebSocket();
    this.ws.subscribeToListenMessage();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  newCustomer() {
    this.router.navigateByUrl('/auth/register-customer');
  }

  newPartner() {
    this.router.navigateByUrl('/auth/register-partner');
  }

  navigateToResetPwd() {
    this.onForgotPwdClickEvent.emit({});
  }

  signInWithGoogle() {
    // this just opens a prompt to Sign In with Google
    var promptGLoginCustomEvent = document.createEvent('HTMLEvents');
    promptGLoginCustomEvent.initEvent('promptGoogleSignin', true, true);
    promptGLoginCustomEvent['google_client_id'] = this.appState.get(
      ApiConstants.GOOGLE_CLIENT_ID 
    );
    window.dispatchEvent(promptGLoginCustomEvent);
  }

  signInWithFacebook() {
    if (typeof FB !== 'undefined') {
      new Promise<any>((resolve) => FB.getLoginStatus(resolve)).then(
        (fbResponse) => {
          this.fbLoginStatusChangeCallback(fbResponse);
        }
      );
    }
  }

  loginTries: number = 0;
  fbLoginStatusChangeCallback(response: any) {
    console.log(response); // The current login status of the person.
    if (response.status === 'connected') {
      // Logged into your webpage and Facebook.
      this.loginUserWithFacebookToken(response.authResponse?.accessToken);
    } else {
      // Not logged into your webpage or we are unable to tell.
      this.loginTries++;
      if (this.loginTries > 2) {
        return; // allow max 2 retries
      }
      if (typeof FB !== 'undefined') {
        new Promise<any>((resolve) => FB.login(resolve)).then((fbResponse) => {
          this.fbLoginStatusChangeCallback(fbResponse);
        });
      }
    }
  }

  loginUserWithGoogleToken(userData: any, gtoken: string) {
    // userData.email; // given_name, family_name, name,

    this.toastr.info('Please wait.. while we log you in.');

    this.auth.loginWithGoogleToken(userData.email, gtoken).subscribe(
      (resp) => {
        this.loginSuccess(resp, resp.username);
      },
      (err) => {
        this.toastr.error('Login failed. ' + err.error.message, 'ERROR');
        this.toastr.info(
          'You might not have an account here with the Google email ID.'
        );
      }
    );
  }

  loginUserWithFacebookToken(fbUserToken: string) {
    this.toastr.info('Please wait.. while we log you in.');
    this.auth.loginWithFacebookToken(fbUserToken).subscribe(
      (resp) => {
        this.loginSuccess(resp, resp.username);
      },
      (err) => {
        this.toastr.error('Login failed. ' + err.error.message, 'ERROR');
        this.toastr.info(
          "You might not have an account here with the Facebook's primary email ID."
        );
      }
    );
  }

  navigateToLogin(from: boolean){
    this.signUpButtonEvent.emit(from);
  }
}

class FB_ {
  public static getLoginStatus(callback) {
    console.log('inside our dummy fn');
    callback({ token: 'dummytoken' });
  }
}