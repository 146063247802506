import { Injectable } from "@angular/core";

@Injectable()
export class LayoutService {
    private menuCollapsed: boolean;
    private menuCollapsedListeners: Function[];
    constructor() {
        this.menuCollapsed = true;
        this.menuCollapsedListeners = [];
    }
    public toggleMenu() {
        this.menuCollapsed = !this.menuCollapsed;
        this.menuCollapsedListeners.forEach(f => {
            f(this.menuCollapsed);
        });
    }
    public registerMenuCollapsedListener(listener: Function) {
        this.menuCollapsedListeners.push(listener);
    }
}