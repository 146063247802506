import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from './core/_services/order.service';
import { AppState } from './core/_store/app.state';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'
]
})

export class AppComponent implements OnInit {
  title = 'gavel-go';

  constructor(private router: Router, private orderService: OrderService,
    private toastr: ToastrService, private appState: AppState, private socketService: SocketService) {

  }

  ngOnInit(): void {
    // this.handleOrderPaymentCallback();
    this.handleSellerCCVerification();
  }

  handleOrderPaymentCallback() {
    const parameters = new URLSearchParams(window.location.search);  
    if (!parameters.has('payorder')) {
      const payOrder: string = parameters.get('payorder');
      let request = JSON.parse(payOrder);      
      this.orderService.confirmOrder(request).subscribe(resp => {
        if (request.failed) {
          this.toastr.warning("Something went wrong with payment. Check your order in Orders page");        
        } else {
          this.toastr.success("Your Order has been confirmed. Check your order in Orders page");        
        }

        // this navigation is not working somehow
        this.router.navigateByUrl("/customer/order-view/" + request.orderId);

      });  
    }
  }

  handleSellerCCVerification() {    
    if (window.opener != null) {
      // Enable below two lines when we do collect payments
      // this.toastr.info('Please wait while we verify details.');      
      // setTimeout(() => close(), 2000);
    } 
  }
}
