<bar-rating [rate]="rating" [max]="5" class="deals-star" [theme]="'stars'"
[readOnly]="true">
    <fa-icon
      *ratingInactive
      [icon]="farStar"
      [fixedWidth]="true"
      [size]="starSize"
      style="color: #d2d2d2"
    />
    <fa-icon
      *ratingActive
      [icon]="faStar"
      [fixedWidth]="true"
      [size]="starSize"
      style="color: #FFC120"
    />
    <fa-icon
      *ratingFraction
      [icon]="faStarHalfAlt"
      [fixedWidth]="true"
      [size]="starSize"
      style="color: #FFC120"
    />
</bar-rating>