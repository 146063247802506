// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthAccountService } from '../../_services/auth.account.service';
import {AppConstants} from '../../_utils/appconstants';
import { AppState } from '../../_store/app.state';

@Injectable({ providedIn: 'root' })
export class AuthStateGuard implements CanActivate {
    constructor
    (
        private router: Router,
        private cookieService: CookieService, 
        private authAccountService: AuthAccountService,
        private appState: AppState
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let accessToken = this.cookieService.get(AppConstants.COOKIE_TOKEN);
        let userRole = this.cookieService.get(AppConstants.USER_ROLE);

        if (accessToken != '' && userRole != ''){
            if(userRole == 'SITE_PARTNER'){
                this.router.navigate(['/partner']);
            }
            if(userRole == 'SITE_USER'){
                this.router.navigate(['/customer']);
            }   
            return false;
        }
        return true;
    }

    matchFound(pageUrl: string, accessible: string[]) {
        return accessible.filter(u => pageUrl.indexOf(u) > -1).length > 0
    }
}
