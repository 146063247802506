<form [formGroup]="resetPwdForm" autocomplete="off" novalidate>
  <div class="form-group">
    <label>Email</label>
    <input
      type="email"
      class="form-control"
      formControlName="email"
      placeholder="Enter Your Email"
    />
  </div>
  <div class="form-group">
    <label>Username</label>
    <input
      type="text"
      class="form-control"
      placeholder="Enter Your Username"
      formControlName="username"
    />
    <span class="linkWrap">
      <i class="fa fa-question-circle" aria-hidden="true"></i>
      <a (click)="getUsernameByEmail()">Don't know your username</a>
    </span>
  </div>

  <div class="form-group">
    <span class="linkWrap">
      <i class="fa fa-paper-plane" aria-hidden="true"></i>
      <a (click)="send2FACode()">Send Verification Code</a>
    </span>
  </div>
  <div class="form-group">
    <label>Code</label>
    <input
      class="form-control"
      placeholder="Enter Code"
      formControlName="code"
    />
    <mat-error *ngIf="hasError('code', 'required')">
      TWO Factor Code is required, check your email for it.
    </mat-error>
  </div>
  <div class="form-group">
    <label>Password</label>
    <input
      type="password"
      name="password"
      class="form-control"
      placeholder="Enter Password"
      formControlName="password"
    />
  </div>
  <div class="form-group">
    <label>Confirm Password</label>
    <input
      type="password"
      name="confirmPassword"
      class="form-control"
      placeholder="ConfirmPassword"
      formControlName="confirmPassword"
    />
  </div>
  <div class="btnWrap">
    <button type="submit" class="submit-btn" (click)="submit()">Submit</button>
    <button type="button" class="cancel-btn mt-2" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>
