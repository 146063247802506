
export class Deal {
    dealId: number;
    dealName: string;
    dealType: string;
    description: string;
    categoryId: number;
    categoryName: string;
    subCategoryId: number;
    subCategoryName: string;
    dealOwner: string;
    tags: [];
    featured: boolean;
    highlights: string;
    productKey: string;
    active: boolean;
    effectiveDate: string;
    expiryDate: string;  
    agreement: boolean;
    noStoreLocation: boolean;

    website: string;

    personLimit: number;
    reorderdays: number;
    expiryDays: number;
}