import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealPhoto } from '../_models/deal-photo';
import { DealDetail } from '../_models/deal.detail';
import { Product } from '../_models/product';
import { Rating } from '../_models/rating';
import { AppState } from '../_store/app.state';
import { ApiConstants } from '../_utils/apiconstants';
import { DataService } from './data.service';
import { DealParameters } from '../_models/deal-parameters';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DealsService {
  restUrl: string;

  constructor(private http: HttpClient, private dataService: DataService, private appState: AppState) {
    this.restUrl = this.appState.get(ApiConstants.REST_URL);
  }

  getAllActiveDeals(): Observable<any> {
    let url = this.restUrl + ApiConstants.DEAL;
    return this.dataService.get(url);
  }

  // searchDeals(
  //   searchText: string,
  //   priceRangeStart: number,
  //   priceRangeEnd: number,
  //   keywordId: number,
  //   category: string,
  //   minRatings: number,
  //   location: string
  // ): Observable<any> {
  //   let url =
  //     this.restUrl +
  //     ApiConstants.SEARCH_DEALS +
  //     '?searchText=' +
  //     searchText +
  //     '&priceRangeStart=' +
  //     priceRangeStart +
  //     '&priceRangeEnd=' +
  //     priceRangeEnd +
  //     '&minRatings=' +
  //     minRatings +
  //     '&categoryName=' +
  //     category +
  //     '&keywordId=' +
  //     keywordId +
  //     '&location=' +
  //     location;
  //   return this.dataService.get(url);
  // }
  allDeals(): Observable<any>{
    let url = this.restUrl + ApiConstants.ALLDEALS;

    return this.dataService.get(url);
  }


  getDealCategories(): Observable<any> {
    let url = this.restUrl + ApiConstants.CATEGORY;
    return this.dataService.get(url);
  }

  getDealCategoryNames():Observable<any> {
    let url = this.restUrl + ApiConstants.CATEGORY_NAMES;
    return this.dataService.get(url);
  }

  searchDeals(searchText: string, priceRangeStart: number, 
            priceRangeEnd: number, keywordId: number, category: string, 
            minRatings: number, location: string, sortOrder: string, page: any, size: any): Observable<any> {


        let url = this.restUrl + ApiConstants.SEARCH_DEALS + '?searchText=' + searchText
            + '&priceRangeStart='+priceRangeStart + '&priceRangeEnd='+priceRangeEnd 
            + '&minRatings='+ minRatings + '&categoryName=' + category
            + '&keywordId=' + keywordId + '&location=' + location + '&sortOrder=' + sortOrder 
            + "&page=" + page + "&size=" + size;
          
        return this.dataService.get(url);
  }


MultiSearchDeals(dealParameters: DealParameters): Observable<any>{ 
    let url = this.restUrl + ApiConstants.MULTI_SEARCH_DEALS;
    const reqHeader: Object = {
      responseType: 'json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
    let headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    
    return this.http.post(url, dealParameters, reqHeader);
  }


  public getDealsOfTheDay2(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(
      this.oneDeal(
        'assets/images/stardeal1.png',
        'Bluetooth 5.0 Headset TWS Wireless Earphone',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/stardeal2.png',
        'Deep Pocket Merit Linens Microfible Sheet set',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/stardeal3.png',
        'Rugged Rubberhard Shockproof Showcase',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/stardeal4.png',
        'Apple Iphone 7 / 8 plus oprotective shockproof',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/stardeal1.png',
        'Bluetooth 5.0 Headset TWS Wireless Earphone',
        ''
      )
    );
    return deals;
  }

  public getDealsOfTheDay1(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(this.oneDeal('assets/images/game.png', 'Game Deal', 'Gamezone'));
    deals.push(
      this.oneDeal('assets/images/spa.png', 'Male & Female One time', 'SPA')
    );
    deals.push(
      this.oneDeal('assets/images/exp.png', 'Credit Card Payment', 'Experian')
    );
    deals.push(
      this.oneDeal('assets/images/kera.png', 'Beauty Treatment', 'Keranique')
    );
    deals.push(
      this.oneDeal('assets/images/spa.png', 'Male & Female One time', 'SPA')
    );
    return deals;
  }

  public getDealsOfTheDay(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(
      this.oneDeal(
        'assets/images/iphone.png',
        'Samsung Galaxy F13 (Sunrise Copper, 64 GB)',
        'Amazon'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/iphone.png',
        'Samsung Galaxy F13 (Sunrise Copper, 64 GB)',
        'Amazon'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/iphone.png',
        'Samsung Galaxy F13 (Sunrise Copper, 64 GB)',
        'Amazon'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/iphone.png',
        'Samsung Galaxy F13 (Sunrise Copper, 64 GB)',
        'Amazon'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/iphone.png',
        'Samsung Galaxy F13 (Sunrise Copper, 64 GB)',
        'Amazon'
      )
    );
    return deals;
  }

  public getTopDeals(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(
      this.oneDeal(
        'assets/images/dominosImg.png',
        '10% off on Dominos',
        'Dominos'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/dominosImg.png',
        '10% off on Dominos',
        'Dominos'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/dominosImg.png',
        '10% off on Dominos',
        'Dominos'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/dominosImg.png',
        '10% off on Dominos',
        'Dominos'
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/dominosImg.png',
        '10% off on Dominos',
        'Dominos'
      )
    );
    return deals;
  }

  public getDealsUnder200(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(
      this.oneDeal(
        'assets/images/under1.png',
        'FurHeeaven Sofa Style Orthopaedic Pet dog bed',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/under2.png',
        'Samsung Sterio Headphone tuned',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/under3.png',
        'Silicon Replacement Band for Apple Watch',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/under4.png',
        'Hotel 5th Avenue Lux Paisley',
        ''
      )
    );
    deals.push(
      this.oneDeal(
        'assets/images/under2.png',
        'Samsung Sterio Headphone tuned',
        ''
      )
    );
    return deals;
  }

  public getDealsYouMayLike(): DealDetail[] {
    let deals: DealDetail[] = [];
    deals.push(
      this.oneDeal('assets/images/kfc.png', 'Burger & Chicken Combo', 'KFC')
    );
    deals.push(
      this.oneDeal('assets/images/beer.png', 'Beer with Starters', 'Beer Bar')
    );
    deals.push(
      this.oneDeal('assets/images/kulcha.png', 'Pizza Combo', 'Pizza Hut')
    );
    deals.push(
      this.oneDeal(
        'assets/images/body.png',
        '4 kick boxing classes',
        'Ilovekickboxing.com'
      )
    );
    deals.push(
      this.oneDeal('assets/images/kulcha.png', 'Pizza Combo', 'Pizza Hut')
    );
    return deals;
  }

  public getCouponsFavBrands(): any[] {
    return [
      { id: 1, image: 'assets/images/logo/1.png' },
      { id: 1, image: 'assets/images/logo/2.png' },
      { id: 1, image: 'assets/images/logo/3.png' },
      { id: 1, image: 'assets/images/logo/4.png' },
      { id: 1, image: 'assets/images/logo/5.png' },
      { id: 1, image: 'assets/images/logo/6.png' },
      { id: 1, image: 'assets/images/logo/7.png' },
      { id: 1, image: 'assets/images/logo/8.png' },
      { id: 1, image: 'assets/images/logo/9.png' },
      { id: 1, image: 'assets/images/logo/10.png' },
      { id: 1, image: 'assets/images/logo/11.png' },
      { id: 1, image: 'assets/images/logo/12.png' },
      { id: 1, image: 'assets/images/logo/13.png' },
      { id: 1, image: 'assets/images/logo/14.png' },
      { id: 1, image: 'assets/images/logo/15.png' },
      { id: 1, image: 'assets/images/logo/16.png' },
    ];
  }

  // public getAllCoupons(): any[] {
  //   return [
  //     { id: 1, image: 'assets/images/iconImages/beauty.png', title: 'beauty' },
  //     { id: 1, image: 'assets/images/iconImages/cinema.png', title: 'cinema' },
  //     { id: 1, image: 'assets/images/iconImages/coupons.png', title: 'coupon' },
  //     { id: 1, image: 'assets/images/iconImages/goods.png', title: 'goods' },
  //     { id: 1, image: 'assets/images/iconImages/hotel.png', title: 'hotel' },
  //     { id: 1, image: 'assets/images/iconImages/kids.png', title: 'kids' },
  //     { id: 1, image: 'assets/images/iconImages/spa.png', title: 'spa' },
  //     { id: 1, image: 'assets/images/iconImages/travel.png', title: 'travel' },
  //     { id: 1, image: 'assets/images/iconImages/bars.png', title: 'Bars' },
  //     {
  //       id: 1,
  //       image: 'assets/images/iconImages/carwash.png',
  //       title: 'Car Wash',
  //     },
  //     {
  //       id: 1,
  //       image: 'assets/images/iconImages/hairsaloon.png',
  //       title: 'Hair Salon',
  //     },
  //     {
  //       id: 1,
  //       image: 'assets/images/iconImages/dentist.png',
  //       title: 'Dentist',
  //     },
  //     {
  //       id: 1,
  //       image: 'assets/images/iconImages/restaurant.png',
  //       title: 'Restuarant',
  //     },
  //     {
  //       id: 1,
  //       image: 'assets/images/iconImages/water.png',
  //       title: 'Water Park',
  //     },
  //   ];
  // }
  public getAllCoupons(): any[] {
    return [
      { id: 1, image: 'assets/images/icons/automative-repair.svg', title: 'Automotive Repair' },
      { id: 2, image: 'assets/images/icons/auto-sales.svg', title: 'Auto Sales' },
      { id: 3, image: 'assets/images/icons/beauty.svg', title: 'Beauty' },
      { id: 4, image: 'assets/images/icons/cannabis.svg', title: 'Cannabis' },
      { id: 5, image: 'assets/images/icons/contractors.svg', title: 'Contractors' },
      { id: 6, image: 'assets/images/icons/entertainment.svg', title: 'Entertainment' },
      { id: 7, image: 'assets/images/icons/events.svg', title: 'Events' },
      { id: 8, image: 'assets/images/icons/health.svg', title: 'Health' },
      { id: 9, image: 'assets/images/icons/pet-care.svg', title: 'Pet Care' },
      { id: 10, image: 'assets/images/icons/professional-services.svg', title: 'Professional Services' },
      { id: 11, image: 'assets/images/icons/real-estate.svg', title: 'Real Estate' },
      { id: 12, image: 'assets/images/icons/restaurant.svg', title: 'Restaurants' },
      { id: 13, image: 'assets/images/icons/shopping.svg', title: 'Shopping/Retail' },
    ];
  }

  public getBanners(): any[] {
    return [
      { id: 1, image: 'assets/images/banner/banner2.png' },
      { id: 1, image: 'assets/images/banner/banner2.png' },
      { id: 1, image: 'assets/images/banner/banner2.png' },
    ];
  }

  private oneDeal(
    imagePath: string,
    description: string,
    dealName: string
  ): DealDetail {
    let deal = new DealDetail();
    deal.products = [];
    deal.photos = [];
    deal.ratings = [];
    deal.dealId = 1;
    deal.dealName = dealName;
    deal.description = description;
    let product = new Product();
    product.productId = 1;
    product.productName = 'Product Name here';
    product.normalPrice = 33;
    product.dealPrice = 23;
    deal.products.push(product);
    let photo = new DealPhoto();
    photo.photopath = imagePath;
    photo.photoname = 'image_1.jpg';
    deal.photos.push(photo);
    deal.ratings.push(new Rating());
    return deal;
  }

  public getAllRatingForDeal(dealId: number, sortOrder:string,rating: number, page: number, size: number): Observable<any>{
    let url = this.restUrl + `/deal-view-rating?dealId=${dealId}&sortOrder=${sortOrder}&rating=${rating}&page=${page}&size=${size}`;
    return this.dataService.get(url);
  }
}
