import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppState } from "../_store/app.state";
import { ApiConstants } from "../_utils/apiconstants";
import { DataService } from "./data.service";

@Injectable()
export class UserQueryService {

    restUrl: string;

    constructor(private dataService: DataService, private appState: AppState) {
        this.restUrl = this.appState.get(ApiConstants.REST_URL);
    }

    public listUsStates(): Observable<any> {
        let url = this.restUrl + ApiConstants.STATES_OF_US;
        return this.dataService.get(url);
    }
    
    public saveUserQuery(userQuery: any): Observable<any> {
        return this.dataService.create(this.restUrl + ApiConstants.USER_QUERY, userQuery, null);
    }
}