import {Component, Input, Host } from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group';

@Component({
    selector: 'checkbox',
    template: `
    <div (click)="toggleCheck()" class="multi-select-container">
        <input type="checkbox" [checked]="isChecked()" class="multi-select-checkbox" />
        <span class="multi-select-text"><ng-content></ng-content></span>
    </div>`,
    styleUrls: ["./multi-select-checkbox.scss"]
})
export class CheckboxComponent {
    @Input() value: any;

    constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {
    }

    toggleCheck() {
        this.checkboxGroup.addOrRemove(this.value);
    }

    isChecked() {
        return this.checkboxGroup.contains(this.value);
    }
}
