import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../core/_store/app.state';

import { AuthService } from '../../../core/_auth/services/auth.service';
import { RegisterUser } from '../../../core/_models/register.user';
import { ConfirmPasswordValidator } from '../confirm-password.validator';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { RegistrationService } from 'src/app/core/_auth/services/registration.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register-partner',
  templateUrl: './register-partner.component.html',
  styleUrls: ['./register-partner.component.scss']
})
export class RegisterPartnerComponent implements OnInit {

  registrationForm: FormGroup;
  callbackOnRegister: Function;
  isCaptchaSubmitted: boolean;
  isButtonDisabled = true;

  
  constructor(private fb: FormBuilder, private appState: AppState, 
    private registrationService: RegistrationService,
    private toastr: ToastrService) {
      this.isCaptchaSubmitted = false;
    }

  ngOnInit(): void {    
    this.initRegistrationForm();
    this.registerCaptchaEvents();
  }

  registerCaptchaEvents() {
    window.addEventListener('onCaptchaSubmitted', (event: any)=> {      
      console.log('got event: onCaptchaSubmitted', event);      
      this.isCaptchaSubmitted = event.result && 1 == 1;
    });
    window.addEventListener('onCaptchaNotSubmitted', (event: any) => {
      console.log('got event: onCaptchaNotSubmitted', event);      
      this.isCaptchaSubmitted = false;
    });
  }

  setCallbackOnRegister(callbackOnRegister: Function) {
    this.callbackOnRegister = callbackOnRegister;
  }

  agreeTermsAndCondition(event: any){
    if(event.target.checked){
      this.isButtonDisabled=false;
    }else{
      this.isButtonDisabled=true;
    }
  }

  submit() {
    this.isButtonDisabled = true;

    const controls = this.registrationForm.controls;
    // check form
    if (this.registrationForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    
    if (!this.isCaptchaSubmitted) {
      this.toastr.warning("Please click on Capthca checkbox ('I'm not a robot.') before submit." +
      " If you don't see it just before 'Create Account' button, try refreshing the page.");
      return;
    }

    let registerUser:RegisterUser = new RegisterUser()
    registerUser.firstname = controls.firstname.value;
    registerUser.lastname = controls.lastname.value;
    registerUser.email = controls.email.value;
    registerUser.phone = controls.phone.value;
    registerUser.username = controls.username.value;
    registerUser.password = controls.password.value;
    registerUser.partnerType = controls.partnerType.value;    
        
    this.registrationService.registerPartner(registerUser).subscribe(resp => {
      this.toastr.success("Registration successful", "SUCCESS");
      this.registrationForm.reset();
      this.isButtonDisabled = false;
      this.appState.set(AppConstants.VERIFY_EMAIL, registerUser.email);
      this.appState.set(AppConstants.VERIFY_USERNAME, registerUser.username);
      // this.router.navigateByUrl('/auth/verify-email');      
      if (this.callbackOnRegister) {
        this.callbackOnRegister();
      }
    }, err => {
      // TODO: handle error
      this.toastr.error(err.error.status, "ERROR");
      this.isButtonDisabled = false;
    });
  }

  
  initRegistrationForm() {
    this.registrationForm = this.fb.group({
      firstname: [null, Validators.compose([
				Validators.required,				
				Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ],
      lastname: [null, Validators.compose([
				Validators.required,				
        Validators.maxLength(100)
      ])
      ],
      email: [null, Validators.compose([
				Validators.required,
				Validators.email,				
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
      ],
      phone: [null, Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(20)
			])
			],
      username: [null, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(50)
			])
			],
			password: [null, Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
      ],
      confirmPassword: [null, Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
      ],
      partnerType: ['WITH_ORDER']
    },{
			validator: ConfirmPasswordValidator.MatchPassword
		});
  }

  hasError(controlName: string, validationType: string): boolean {
		const control = this.registrationForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
  
}

