<div class="login-container d-flex container">
  <div class="login_formWrap padding-38" [class.w-100]="screenWidth <= 991" [class.w-50]="screenWidth > 991">
    <div class="login_form-header">
      <h4 class="form-top-title">
        Maximize Your Business Reach with
        <span style="color: #ee410d">LocolDeal </span>Business
      </h4>
    </div>

    <ng-container *ngIf="!showResetPwd">
      <h2 class="text-center" style="margin-top: 40px; margin-bottom: 1.2rem; font-weight: bolder;">
        Business Login
      </h2>
      <div class="login_formWrap-inner">
        <form [formGroup]="loginForm" autocomplete="off">
          <div class="form-group">
            <label for="username" style="color: #000; font-size: 14px">Email & Username</label>
            <input type="text" id="username" name="username" class="form-control" formControlName="username"
              placeholder="Enter Email & Username" />
          </div>
          <div class="form-group mb-2">
            <label for="password" style="color: #000; font-size: 14px">Password</label>
            <input type="password" id="password" name="password" class="form-control" formControlName="password"
              placeholder="Enter Password" />
          </div>

          <span class="forgot-pass" (click)="onForgotPwdClick()">
            <i class="fa fa-lock" aria-hidden="true"></i>
            <a>FORGOT PASSWORD</a></span>
          <div class="btnWrap">
            <button type="submit" class="submit-btn" (click)="submit()">Login</button>
          </div>
        </form>

        <div class="w-100 text-center mt-5">
          <span (click)="onSignupButtonClick()" style="color: #000000; font-size: 14px;cursor: pointer;">Don't Have an Account ? <span style="font-weight: bolder;">SIGN
              UP</span>
          </span>
        </div>
      </div>
    </ng-container>

    <div *ngIf="showResetPwd">
      <app-reset-password
        (onCancel)="onResetPwdCancel()"
      ></app-reset-password>
    </div>
  </div>

  <div class="img-wrapper w-50" *ngIf="screenWidth > 991">
    <img src="/assets/images/banner/BusinessLoginBanner.png" style="width: 100%; height: 700px;"
      alt="businessloginbanner">
  </div>
</div>