import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/_auth/_guard/auth.guard';
import { BaseComponent } from './views/theme/base/base.component';
import { AuthStateGuard } from './core/_auth/_guard/authState.guard';
import { PartnerHomePageComponent } from './views/auth/partner-home-page/partner-home-page';
import { BusinessSignUpComponent } from './views/auth/business-signup/business-signup.component';
import { BusinessSignInComponent } from './views/auth/business-signin/business-signin.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthStateGuard],
    loadChildren: () =>
      import('../app/views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'business',
    component: PartnerHomePageComponent,
    children: [
      //{ path: '', redirectTo: '/', pathMatch: 'full' },
      { path: 'signup', component: BusinessSignUpComponent },
      { path: 'signin', component: BusinessSignInComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'partner',
        loadChildren: () =>
          import('../app/views/partner/partner.module').then(
            (m) => m.PartnerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('../app/views/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        canActivate: [AuthGuard],
      },
      { 
        path: '',
        redirectTo: '', 
        pathMatch: 'full',
        canActivate: [AuthStateGuard] 
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
