import { Deal } from './deal';
import { DealPhoto } from './deal-photo';
import { Product } from './product';
import { Rating } from './rating';
import { StoreLocation } from './store-location';

export class DealDetail extends Deal {
    
    products: Product[];
    photos: DealPhoto[];
    address: StoreLocation[];
    ratings: Rating[];
    keywords: any[];
}