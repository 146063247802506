import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/core/_services/coupon.service';
import { isPlatformBrowser } from '@angular/common';

// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.css';

@Component({
  selector: 'app-common-search-page',
  templateUrl: './app-common-search-page.html',
  styleUrls: ['./app-common-search-page.scss'],
})
export class SearchPageComponent implements OnInit, AfterViewInit {
  trendinList: string[];
  searchText: string;
  searchLocation: string;
  showSearchProgress: boolean;
  @ViewChild('searchModal', { static: true }) searchModal: ElementRef;
  @ViewChild('searchPanelOnLaunch', { static: true }) searchPanelOnLaunch: ElementRef;
  private isBrowser: boolean;

  constructor(private router: Router, private couponService: CouponService, private renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object) {
    this.searchText = '';
    this.searchLocation = '';
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.trendinList = ["Local Stores", "Hostinger - Extra 10% OFF", "Qatar Airwayas- Up to 15% OFF", "KFC - Free Offer", "Amazon-Today’s Sale", "McDonalds - Free OFFER", "Hostinger - Extra 10% OFF", "McDonalds - Free OFFER", "Local Coupon", "Local deal"];
  }

  doSearch() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    let closeButton = document.getElementById("closeSearchModal");
    closeButton.click();

    this.showSearchProgress = true;

    this.couponService.brandSearch(this.searchText).subscribe((resp) => {

      if (this.searchText && resp && resp.length > 0) {
        this.router.navigate(['/customer/seller-home', { s: this.searchText }]);

      } else {
        console.log('no brands found with search');
        this.router.navigate([
          '/customer/product-search',
          { q: this.searchText, location: this.searchLocation },
        ]);
      }

      this.showSearchProgress = false;

    }, err => {
      console.log('some error with searching for brand');
      this.showSearchProgress = false;
    });

  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      $("#searchModal").on("hidden.bs.modal", function () {
        $("#searchPanelOnLaunch").val('');
      });
    }
  }
}